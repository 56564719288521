import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// import { AppDetail } from "./components/AppDetail";
import { AppVirtualTour } from "./components/AppVirtualTour";

import { D_AppDet } from "./components/D_AppDet";
import { D_Listing } from "./components/D_Listing";
import { D_AppDtl } from "./components/D_AppDtl";
import { D_ShDtl } from "./components/D_ShDtl";
import { Testing } from "./components/Testing";

import { ReportChart } from "./components/frame/ReportChart";
import { ReportChartSharing } from "./components/frame/ReportChartSharing";
import { CensusData } from "./components/frame/CensusData";
import { CensusDataSharing } from "./components/frame/CensusDataSharing";
import { WalkScore } from "./components/frame/WalkScore";
import { WalkScoreSharing } from "./components/frame/WalkScoreSharing";
import { FindSchool } from "./components/frame/FindSchool";

import { AppMarket } from "./components/AppMarket";
import { AppHome } from "./components/AppHome";

import { NewCondos } from "./components/NewCondos";

import { QrUpload } from "./components/QrUpload";

import { PanoramaView } from "./components/PanoramaView";

import { EditorDemo } from "./components/EditorDemo";
import { ArticleDetail } from "./components/ArticleDetail";
import { S_E_Article } from "./components/S_E_Article";
import { S_Article } from "./components/S_Article";

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" App />
          <Route path="/appdet" component={D_AppDet} />
          <Route path="/listing" component={D_Listing} />
          <Route path="/yiju/dtl" component={D_AppDtl} />
          <Route path="/yiju/shdtl" component={D_ShDtl} />
          <Route path="/test" component={Testing} />

          <Route path="/reportchart" component={ReportChart} />
          <Route path="/shreportchart" component={ReportChartSharing} />
          <Route path="/censusdata" component={CensusData} />
          <Route path="/shcensusdata" component={CensusDataSharing} />
          <Route path="/walkscore" component={WalkScore} />
          <Route path="/shwalkscore" component={WalkScoreSharing} />
          <Route path="/findschool" component={FindSchool} />

          <Route path="/appmarket" component={AppMarket} />
          <Route path="/apphome" component={AppHome} />

          <Route path="/appvirtualtour" component={AppVirtualTour} />
          <Route path="/newcondos" component={NewCondos} />
          <Route path="/qrupload" component={QrUpload} />
          <Route path="/panorama" component={PanoramaView} />

          <Route path="/edtest" component={EditorDemo} />
          <Route path="/edarticle" component={S_E_Article} />
          <Route path="/postsharedmedia" component={S_E_Article} />
          <Route path="/shmedia" component={S_Article} />

          <Route path="/newsdetail" component={ArticleDetail} />
        </Switch>
      </BrowserRouter>
    );
  }
}
