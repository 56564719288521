import React, { Component } from "react";

export class FindSchool extends Component {
  static displayName = FindSchool.name;

  componentDidMount() {
    this.importFindSchoolJS();
    this.createSchoolReport();
  }

  render() {
    return (
      <div>
        <link
          href="https://www.findschool.ca/web/ApiV3/css?appid=xouot6of&fid=1"
          type="text/css"
          rel="stylesheet"
        />
        <div id="findchool">
          <div id="schlocator"></div>
        </div>
      </div>
    );
  }

  importFindSchoolJS() {
    let script = document.querySelector("#script");
    if (script) {
      return;
    }
    script = document.createElement("script");
    script.id = "script";
    script.src = "https://www.findschool.ca/web/ApiV3/js?appid=xouot6of&fid=1";
    document.querySelector("body").appendChild(script);
  }

  async createSchoolReport() {
    window.onload = function () {
      let url = document.location.toString();
      let ngw = url.split("?").splice(1, 1)[0];
      var myOptions = {
        SID: "",
        DivMainID: "schlocator",
        IsInnerMap: 0,
      };
      if (typeof sysparts != "undefined") {
        try {
          let latlng = window.atob(ngw.replaceAll("~", "=")).split(","); // load detail information
          // eslint-disable-next-line
          var schLocator = new sysparts.schlocator(myOptions);
          // eslint-disable-next-line
          schLocator.searchGeo(latlng[0], latlng[1]);
          let header = document.getElementById("sysparts_schlocator1_top_logo");
          header.remove();
          let footer = document.getElementById("sysparts_schlocator1_bottom");
          footer.remove();
        } catch {}
      }
    };
  }
}
