import React, { Component } from "react";
import { Button, Tabs, Tab } from "react-bootstrap";
import Slider from "react-slick";
import classnames from "classnames";
import axios from "axios";
import enj from "./language/en.json";
import zhj from "./language/zh.json";

import { ReadValue, priceSwitch } from "./function/Basic.js";
import {
  gesture,
  findschoolhost,
  datahost,
  pic_host,
  pic_link,
} from "./function/Const.js";
import {
  initImageLinks,
  SetupPageValues,
  // createLog,
  createHistory,
  createCommunityChart,
  createCensusData,
  createWalkScore,
  createSchoolReport,
  fixFrameHeight,
  addAccordionClick,
  MeterFeetClick,
} from "./function/Detail.js";
import "bootstrap/dist/css/bootstrap.min.css";
// import "../style/AppDetail.css";

export class D_AppDtl extends Component {
  static displayName = D_AppDtl.name;

  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      tk: null,
      dtl: null,
      asl: null,
      map: null,
      walkscore_loaded: false,
      school_loaded: false,
      community_loaded: false,
      census_loaded: false,
      images: [],
      soldList: [],
      leasedList: [],
      featuredList: [],
    };
    // this.BackClick = this.BackClick.bind(this);
    // this.TabsToggle = this.TabsToggle.bind(this);
    // this.MeterFeetClick = this.MeterFeetClick.bind(this);
  }

  componentDidMount() {
    this.loadDetail();
    this.initLanguage();
    addAccordionClick();
  }

  render() {
    let setting0 = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    let setting1 = {
      dots: false,
      infinite: true,
      speed: 300,
      // lazyLoad: true,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: true,
    };

    return (
      <div className="AppListing">
        <div className="wrapper">
          <div className="wrapper_margin">
            <div className="back_page">
              <Button
                id="btn-back"
                onClick={() => {
                  this.BackClick();
                }}
              >
                <i className="fa fa-angle-left"></i>
              </Button>
            </div>
            <div className="property_locationsec">
              <p>
                <i className="fa fa-map-marker-alt"></i>
                <i id="address"></i>
              </p>
            </div>
            {/* property_locationsec */}

            <div className="detail_banner_sec">
              <div className="slider single-item">
                <Slider {...setting0} id="testing">
                  {this.state.images.map(function (item) {
                    return (
                      <div key={item.ngw}>
                        <div className="inner_bannersec">
                          <img src={item.link} alt="" />
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
              {/* slider */}
            </div>
            {/* banner_sec */}

            <div className="proprice_infosec section_margin">
              <div className="property_pricesec">
                <h1 id="price"></h1>
                <h2>
                  <span id="mls"></span>
                </h2>
                <p>
                  {/* {this.state.lang["dtl_types"]} */}
                  <span id="typestyle"></span>
                </p>
              </div>
              {/* property_pricesec */}
              <ul>
                <li>
                  <i className="fa fa-bath"></i> <span id="tit-bedrooms"></span>
                </li>
                <li>
                  <i className="fa fa-bed"></i> <span id="tit-bathrooms"></span>
                </li>
                <li>
                  <i className="fa fa-car"></i> <span id="tit-parking"></span>
                </li>
              </ul>
            </div>
            {/* proprice_infosec */}

            <div className="summary_contsec section_margin">
              <Tabs
                className="tab"
                defaultActiveKey="summary"
                id="detail-tab-profile-summary"
              >
                <Tab
                  eventKey="summary"
                  className="tabcontent"
                  title={this.state.lang["dtl_summary"]}
                >
                  <div className="common_infosec summary_tabsec">
                    <ul>
                      <li>
                        <p>{this.state.lang["dtl_type"]}</p>{" "}
                        <strong>
                          <p id="pro-type"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_bedrooms"]}</p>{" "}
                        <strong>
                          <p id="pro-bedrooms"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_kitchen"]}</p>{" "}
                        <strong>
                          <p id="pro-kitchen"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_bathrooms"]}</p>{" "}
                        <strong>
                          <p id="pro-bathrooms"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_lotsize"]}</p>{" "}
                        <strong>
                          <p id="pro-lotsize"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_taxes"]}</p>{" "}
                        <strong>
                          <p id="pro-taxes"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_parking"]}</p>{" "}
                        <strong>
                          <p id="pro-parking"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_listed"]}</p>{" "}
                        <strong>
                          <p id="pro-listeddate"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_update"]}</p>{" "}
                        <strong>
                          <p id="pro-updatedate"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_basement"]}</p>{" "}
                        <strong>
                          <p id="pro-basement"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_brokered"]}</p>{" "}
                        <strong>
                          <p id="pro-broker"></p>
                        </strong>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div>{this.state.lang["dtl_extras"]}</div>
                    <strong>
                      <p id="pro-extras"></p>
                    </strong>
                  </div>
                </Tab>
                <Tab
                  eventKey="details"
                  className="tabcontent"
                  title={this.state.lang["dtl_details"]}
                >
                  <div className="common_infosec detail_tabsec">
                    <ul>
                      <li>
                        <p>{this.state.lang["dtl_style"]}</p>{" "}
                        <strong>
                          <p id="pro-construction-style"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_frontingon"]}</p>{" "}
                        <strong>
                          <p id="pro-front-on"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_watersupply"]}</p>{" "}
                        <strong>
                          <p id="pro-water-supply"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_acsystem"]}</p>{" "}
                        <strong>
                          <p id="pro-ac-system"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_heatingfuel"]}</p>{" "}
                        <strong>
                          <p id="pro-heating-fuel"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_heatingtype"]}</p>{" "}
                        <strong>
                          <p id="pro-heating-type"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_fireplace"]}</p>{" "}
                        <strong>
                          <p id="pro-fire-place"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_centralvac"]}</p>{" "}
                        <strong>
                          <p id="pro-central-vac"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_sewer"]}</p>{" "}
                        <strong>
                          <p id="pro-sewer"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_construction"]}</p>{" "}
                        <strong>
                          <p id="pro-construction"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_municipality"]}</p>{" "}
                        <strong>
                          <p id="pro-municipality"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_swimmingpool"]}</p>{" "}
                        <strong>
                          <p id="pro-swimming-pool"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_basementtype"]}</p>{" "}
                        <strong>
                          <p id="pro-basement-type"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_basementfeatures"]}</p>{" "}
                        <strong>
                          <p id="pro-basement-features"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_sizetotal"]}</p>{" "}
                        <strong>
                          <p id="pro-size-total"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_amenities"]}</p>{" "}
                        <strong>
                          <p id="pro-amenities"></p>
                        </strong>
                      </li>
                    </ul>
                  </div>
                </Tab>
                <Tab
                  eventKey="rooms"
                  className="tabcontent"
                  title={this.state.lang["dtl_rooms"]}
                >
                  <Button
                    id="pro-btn-mf"
                    style={{ width: "98%" }}
                    onClick={() => {
                      MeterFeetClick(this.state.dtl);
                    }}
                  >
                    Change To Feet
                  </Button>
                  <div className="common_infosec room_tabsec">
                    <ul>
                      <li>
                        <div>
                          <div
                            id="pro-room0-0"
                            className="pro_roomleft"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room0-1"
                            className="pro_roomright"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p
                          id="pro-room0-2"
                          className="pro_room"
                          style={{ width: "100%" }}
                        ></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room1-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room1-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room1-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room2-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room2-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room2-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room3-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room3-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room3-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room4-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room4-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room4-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room5-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room5-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room5-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room6-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room6-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room6-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room7-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room7-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room7-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room8-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room8-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room8-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room9-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room9-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room9-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room10-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room10-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room10-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room11-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room11-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room11-2" style={{ width: "100%" }}></p>
                      </li>
                    </ul>
                  </div>
                </Tab>
              </Tabs>
              <div>
                <div>{this.state.lang["dtl_remarks"]}</div>
                <div id="pro-remarks" style={{ width: "98%" }}></div>
              </div>
            </div>

            <div className="main_accordinsec section_margin">
              {/* <button className="accordion">
                <i className="fas fa-address-card"></i> Property Log
              </button>
              <div className="panel">
                <div className="inner_prohistory_sec">
                  <div id="detail_log">
                    <ul className="main_historysec"></ul>
                  </div>
                </div> */}
              {/* inner_prohistory_sec */}
              {/* </div> */}
              {/* panel */}

              <button className="accordion">
                <i className="fas fa-address-card"></i> Property History
              </button>
              <div className="panel">
                <div className="inner_prohistory_sec">
                  {/* <span className="accordin_titlecontsec">
                    Buy and sell details of the 106 calm waters crescent.
                  </span> */}
                  <div id="detail_history">
                    <ul className="main_historysec"></ul>
                  </div>
                </div>
                {/* inner_prohistory_sec */}
              </div>
              {/* panel */}

              <button className="accordion">
                <i className="fas fa-clipboard-list"></i> Census Data
              </button>
              <div className="panel">
                <iframe
                  id="iframe_census"
                  src=""
                  style={{
                    width: "100%",
                  }}
                  frameBorder="no"
                  border="0"
                  marginWidth="0"
                  marginHeight="0"
                  scrolling="no"
                  allowtransparency="yes"
                  position="relative"
                  overflow="auto"
                />
              </div>
              {/* panel */}

              <button className="accordion">
                <i className="fas fa-clipboard-list"></i> Community Sold/Lease
                Reports
              </button>
              <div className="panel">
                <iframe
                  id="iframe_community"
                  src=""
                  style={{
                    width: "100%",
                  }}
                  frameBorder="no"
                  border="0"
                  marginWidth="0"
                  marginHeight="0"
                  scrolling="no"
                  allowtransparency="yes"
                  position="relative"
                  overflow="auto"
                />
              </div>
              {/* panel */}

              <button className="accordion">
                <i className="fas fa-school"></i> Avaliable Schools
              </button>
              <div className="panel">
                <iframe
                  id="iframe_school"
                  src=""
                  style={{
                    width: "100%",
                    height: document.body.clientWidth * 1.59,
                  }}
                  frameBorder="no"
                  border="0"
                  marginWidth="0"
                  marginHeight="0"
                  scrolling="no"
                  allowtransparency="yes"
                  position="relative"
                  overflow="auto"
                />
              </div>
              {/* panel */}

              <button className="accordion">
                <i className="fas fa-clipboard-list"></i> WalkScore Report
              </button>
              <div className="panel">
                <iframe
                  id="iframe_walkscore"
                  src=""
                  style={{
                    width: "100%",
                  }}
                  frameBorder="no"
                  border="0"
                  marginWidth="0"
                  marginHeight="0"
                  scrolling="no"
                  allowtransparency="yes"
                  position="relative"
                  overflow="auto"
                />
              </div>
              {/* panel */}

              {/* <button className="accordion">
                <i className="fas fa-calculator"></i> Mortagage Calculator
              </button>
              <div className="panel">
                <div className="detail_calculatorsec">
                  <div className="calculator_fieldsec">
                    <div className="outer_calfieldsec">
                      <label>Home Price</label>
                      <input type="text" className="input_calsec" name="" />
                    </div>

                    <div className="outer_calfieldsec">
                      <label>Down Payment</label>
                      <input type="text" className="input_calsec" name="" />
                    </div>

                    <div className="outer_calfieldsec">
                      <label>Mortgage Term</label>
                      <input type="text" className="input_calsec" name="" />
                    </div>

                    <div className="mainouter_calfieldsec">
                      <div className="outer_calfieldsec">
                        <label>Perc(%)</label>
                        <input type="text" className="input_calsec" name="" />
                      </div>

                      <div className="outer_calfieldsec">
                        <label>Int. Rate</label>
                        <input type="text" className="input_calsec" name="" />
                      </div>
                    </div>

                    <div className="calculator_resultsec">
                      <h2>Mortgage Payment -- </h2>
                      <span>$4500/Month</span>
                    </div> */}
              {/* calculator_resultsec */}
              {/* </div> */}
              {/* calculator_fieldsec */}
              {/* </div> */}
              {/* detail_calculatorsec */}
              {/* </div> */}
              {/* panel */}

              {/* <button className="accordion">
                <i className="fas fa-home"></i> Home Value & Rental Evaluation{" "}
              </button>
              <div className="panel">
                <div className="property_evaluationsec">
                  <div className="evaluation_detsec">
                    <ul>
                      <li>
                        <p>Home Value Evaluation: </p>{" "}
                        <strong> $1,000,000</strong>
                      </li>
                      <li>
                        <p>Home Rental Evaluation: </p>{" "}
                        <strong> $3000/month</strong>
                      </li>
                    </ul>
                  </div> */}
              {/* evaluation_detsec */}
              {/* </div> */}
              {/* property_evaluationsec */}
              {/* </div> */}
              {/* panel */}
            </div>
            {/* main_accordinsec */}

            <div className="features_iconsec section_margin">
              <div className="inner_featiconsec">
                <ul>
                  <li>
                    <img
                      src={require("./../images/map_icon.png").default}
                      alt=""
                    />{" "}
                    <span>Map</span>
                  </li>
                  <li>
                    <img
                      src={require("./../images/transit_icon.png").default}
                      alt=""
                    />{" "}
                    <span>Transit Score</span>
                  </li>
                  <li>
                    <img
                      src={require("./../images/community_icon.png").default}
                      alt=""
                    />{" "}
                    <span>Community</span>
                  </li>
                  <li>
                    <img
                      src={require("./../images/street_icon.png").default}
                      alt=""
                    />{" "}
                    <span>Street View</span>
                  </li>
                </ul>
              </div>
              {/* inner_featiconsec */}
            </div>
            {/* features_iconsec */}

            <div className="listings_sec section_margin">
              <div className="main_headingsec">
                <h1>Similar Properties Sold in this Community </h1>
              </div>
              {/* main_headingsec */}

              <div className="listing_wrappersec">
                <div className="slider variable-width">
                  <Slider {...setting1}>
                    {this.state.soldList.map(function (item) {
                      return (
                        <div key={item.I}>
                          <a href={item.link}>
                            <div className="inner_listingsec">
                              <div className="listing_imgsec">
                                <img src={item.pic} alt="" />
                              </div>
                              {/* listing_imgsec */}
                              <div className="listing_detsec">
                                <h2>${item.a}</h2>
                                <span>
                                  <i className="fa fa-map-marker-alt"></i>{" "}
                                  {item.c}
                                </span>
                                <ul>
                                  <li>
                                    {" "}
                                    <i className="fa fa-bed"></i>{" "}
                                    <p>Bed : {item.a1}</p>
                                  </li>
                                  <li>
                                    {" "}
                                    <i className="fa fa-bath"></i>{" "}
                                    <p>Bath : {item.b1}</p>
                                  </li>
                                  <li>
                                    {" "}
                                    <i className="fa fa-car"></i>{" "}
                                    <p>Parking : {item.c1}</p>
                                  </li>
                                </ul>
                                <div className="property_status">
                                  <button>For {item.e}</button>
                                </div>
                                {/* forsale */}
                              </div>
                              {/* listing_detsec */}
                            </div>
                            {/* inner_listingsec */}
                          </a>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                {/* slider */}
              </div>
              {/* listing_wrappersec */}
            </div>
            {/* listings_sec */}

            <div className="listings_sec section_margin">
              <div className="main_headingsec">
                <h1>Similar Properties Rented in this Community</h1>
              </div>
              {/* main_headingsec */}

              <div className="listing_wrappersec">
                <div className="slider variable-width">
                  <Slider {...setting1}>
                    {this.state.leasedList.map(function (item) {
                      return (
                        <div key={item.I}>
                          <a href={item.link}>
                            <div className="inner_listingsec">
                              <div className="listing_imgsec">
                                <img src={item.pic} alt="" />
                              </div>
                              {/* listing_imgsec */}
                              <div className="listing_detsec">
                                <h2>${item.a}</h2>
                                <span>
                                  <i className="fa fa-map-marker-alt"></i>{" "}
                                  {item.c}
                                </span>
                                <ul>
                                  <li>
                                    {" "}
                                    <i className="fa fa-bed"></i>{" "}
                                    <p>Bed : {item.a1}</p>
                                  </li>
                                  <li>
                                    {" "}
                                    <i className="fa fa-bath"></i>{" "}
                                    <p>Bath : {item.b1}</p>
                                  </li>
                                  <li>
                                    {" "}
                                    <i className="fa fa-car"></i>{" "}
                                    <p>Parking : {item.c1}</p>
                                  </li>
                                </ul>
                                <div className="property_status">
                                  <button>For {item.e}</button>
                                </div>
                                {/* forsale */}
                              </div>
                              {/* listing_detsec */}
                            </div>
                            {/* inner_listingsec */}
                          </a>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                {/* slider */}
              </div>
              {/* listing_wrappersec */}
            </div>
            {/* listings_sec */}

            <div className="listings_sec section_margin">
              <div className="main_headingsec">
                <h1>Featured Listings</h1>
                <p>Latest Featured Listings in this community</p>
              </div>
              {/* main_headingsec */}

              <div className="listing_wrappersec">
                <div className="slider variable-width">
                  <Slider {...setting1}>
                    {this.state.featuredList.map(function (item) {
                      return (
                        <div key={item.I}>
                          <a href={item.link}>
                            <div className="inner_listingsec">
                              <div className="listing_imgsec">
                                <img src={item.pic} alt="" />
                              </div>
                              {/* listing_imgsec */}
                              <div className="listing_detsec">
                                <h2>${item.a}</h2>
                                <span>
                                  <i className="fa fa-map-marker-alt"></i>{" "}
                                  {item.c}
                                </span>
                                <ul>
                                  <li>
                                    {" "}
                                    <i className="fa fa-bed"></i>{" "}
                                    <p>Bed : {item.a1}</p>
                                  </li>
                                  <li>
                                    {" "}
                                    <i className="fa fa-bath"></i>{" "}
                                    <p>Bath : {item.b1}</p>
                                  </li>
                                  <li>
                                    {" "}
                                    <i className="fa fa-car"></i>{" "}
                                    <p>Parking : {item.c1}</p>
                                  </li>
                                </ul>
                                <div className="property_status">
                                  <button>For {item.e}</button>
                                </div>
                                {/* forsale */}
                              </div>
                              {/* listing_detsec */}
                            </div>
                            {/* inner_listingsec */}
                          </a>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                {/* slider */}
              </div>
              {/* listing_wrappersec */}
            </div>
            {/* listings_sec */}

            {/* <div className="detail_contactsec section_margin">
              <div className="main_headingsec">
                <h1>Contact us</h1>
              </div>
              <div className="detcontact_fieldsec">
                <input
                  type="text"
                  className="inputcont_field"
                  name=""
                  placeholder="Your Name (Required)"
                  required=""
                />
                <input
                  type="text"
                  className="inputcont_field"
                  name=""
                  placeholder="Your Phone Number (Required)"
                  required=""
                />
                <input
                  type="text"
                  className="inputcont_field"
                  name=""
                  placeholder="Your Email Address (Required)"
                  required=""
                />
                <textarea
                  className="textareacont_field"
                  placeholder="Message"
                  className=""
                ></textarea>
                <button className="btncont_field">
                  <i className="fas fa-user-tie"></i> Contact Agent
                </button>
              </div>
            </div> */}

            {/* <div className="detail_agentsec section_margin">
              <div className="main_headingsec">
                <h1>Agent</h1>
              </div>
              <div className="agent_detsec">
                <div className="main_agentpic_sec">
                  <img src={require("./../images/logo192.png").default} alt="" />
                </div>
                <div className="main_agentdetsec">
                  <h2>
                    Yiju <small>-- Better Life with Us</small>
                  </h2>
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fa fa-comments"></i> Message
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-phone-alt"></i> Phone
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-share"></i> Share
                      </a>
                    </li>
                  </ul>
                  <div className="brokerage_infosec">
                    <img src={require("./../images/logo192.png").default} alt="" />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* wrapper_margin */}
        </div>
        {/* wrapper */}

        <br />
        <div id="footer"></div>
      </div>
    );
  }

  initLanguage() {
    if (navigator.language === "zh-CN") {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadDetail() {
    try {
      var url = document.location.toString();
      if (url[url.length - 1] === "=") {
        // remove the last '=' character
        url = url.slice(0, -1);
      }
      let paras = url.split("?").splice(1, 1)[0].split("&");
      let tk = paras[0].split("=")[1];
      let ngw = paras[1].split("=")[1]; // save token and 'ngw' params

      this.setState({ tk: tk, ngw: ngw });
      let decode = window.atob(ngw.replaceAll("~", "=")).split(","); // load detail information
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param = "\"{'tk':'" + tk + "','id':'" + decode[1] + "'}\"";
      axios.post("/detail/app", param).then((res) => {
        this.setState({ dtl: res.data });

        // Data
        if (this.state.dtl != undefined) {
          SetupPageValues(this.state.dtl);
        }

        // Log
        // axios.post("/detail/log", param).then((res) => {
        //   createLog(res.data);
        // });

        // History
        axios.post("/detail/history", param).then((res) => {
          createHistory(res.data);
        });

        // load community / findschool / similar listings
        axios.post("/detail/data", param).then((res) => {
          // save asl data
          this.setState({ asl: res.data });

          // Load Images
          if (this.state.asl != undefined) {
            let arr = initImageLinks(this.state.tk, this.state.asl);
            this.setState({ images: arr });
          }
        });

        // load community / findschool / similar listings
        axios.post("/detail/map", param).then((res) => {
          // save map data
          this.setState({ map: res.data });

          // Community
          this.setState({
            community_loaded: createCommunityChart(
              this.state.dtl,
              this.state.map,
              this.state.tk,
              this.state.community_loaded
            ),
          });
          fixFrameHeight("iframe_community", 1.03);

          // Census
          this.setState({
            census_loaded: createCensusData(
              this.state.dtl,
              this.state.tk,
              this.state.census_loaded
            ),
          });
          fixFrameHeight("iframe_census", 0.97);

          // Walkscore
          this.setState({
            walkscore_loaded: createWalkScore(
              this.state.dtl,
              this.state.tk,
              this.state.walkscore_loaded
            ),
          });
          fixFrameHeight("iframe_walkscore", 1.01);

          // FindSchool
          this.setState({
            school_loaded: createSchoolReport(
              this.state.map,
              this.state.school_loaded
            ),
          });
          if (document.location.host == findschoolhost) {
            fixFrameHeight("iframe_school", 0.55);
          }

          // similar sold listings
          let param_sold =
            "\"{'mt':'c;20;1;dF;u4,E,3;!,=,=;COM,S," +
            this.state.map.X +
            "_" +
            this.state.map.Y +
            "|" +
            0.007 +
            "','tk':'" +
            tk +
            "'}\"";
          axios.post("/app/list", param_sold).then((res) => {
            this.setState({ soldList: this.fillList(res.data) });
          });

          // similar leased listings
          let param_leased =
            "\"{'mt':'c;20;1;F;u4,E,3;!,=,=;COM,L," +
            this.state.map.X +
            "_" +
            this.state.map.Y +
            "|" +
            0.007 +
            "','tk':'" +
            tk +
            "'}\"";
          axios.post("/app/list", param_leased).then((res) => {
            this.setState({ leasedList: this.fillList(res.data) });
          });

          // similar featured listings
          let param_featured =
            "\"{'mt':'a;20;1;dF;u4,E,3;!,=,=;COM,S," +
            this.state.map.X +
            "_" +
            this.state.map.Y +
            "|" +
            0.007 +
            "','tk':'" +
            tk +
            "'}\"";
          axios.post("/app/list", param_featured).then((res) => {
            this.setState({ featuredList: this.fillList(res.data) });
          });
        });
      });
    } catch {}
  }

  fillList = (data) => {
    let str_0 = gesture + document.location.host + "/listing?";
    let str_1 = "=";
    let str_2 = "&ngw=";
    data.forEach((element) => {
      // fill detail link
      let ngw_0 = "940682259,";
      let ngw_1 = ",";
      let dt = "2021-01-08 12:25:08";
      let ngw_2 = ",";
      var ngw = window.btoa(ngw_0 + element.I + ngw_1 + dt + ngw_2 + element.O);
      ngw = ngw.replace("=", "~");
      element["link"] = str_0 + this.state.tk + str_1 + ngw;
      // fill picture link
      element["pic"] =
        gesture + pic_host + pic_link + this.state.tk + str_2 + ngw;
      // fix parking
      if (element["c1"] === null) {
        element["c1"] = 0;
      }
      // fix address
      element["c"] = element["c"].replaceAll(",", " ").replaceAll("|", ",");
      // fix price showing
      element["a"] = priceSwitch(element["a"]);
    });
    return data;
  };

  BackClick() {
    window.history.go(-1);
  }
}
