import React, { Component } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import ReactSelect from "react-select";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  SetupBothDistribution,
  SetChartListing,
  SetChartPrice,
  SetChartMarket,
  SetChartPartition,
} from "./function/Chart.js";
import { JsonToKeyValue } from "./function/Basic.js";
import { datahost } from "./function/Const.js";
import enj from "./language/en.json";
import zhj from "./language/zh.json";
import area from "./data/area.json";
import region from "./data/region.json";
import "../style/MonthlyChart.css";

export class AppMarket extends Component {
  static displayName = AppMarket.name;

  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      reports: [],
      distrib: null,
      loaded: false,
      tk: null,
      bk: null,
      ls: null,
      st: null,
      ed: null,
      cities: null,
      communities: null,
    };

    this.radio1Year = this.radio1Year.bind(this);
    this.radio2Years = this.radio2Years.bind(this);
    this.radio3Years = this.radio3Years.bind(this);
    this.radioSold = this.radioSold.bind(this);
    this.radioLeased = this.radioLeased.bind(this);
    this.btnSelectDetached = this.btnSelectDetached.bind(this);
    this.btnSelectSemi = this.btnSelectSemi.bind(this);
    this.btnSelectTown = this.btnSelectTown.bind(this);
    this.btnSelectCondo = this.btnSelectCondo.bind(this);
    this.btnSelectOthers = this.btnSelectOthers.bind(this);
    this.btnSelectALL = this.btnSelectALL.bind(this);
    this.btnUpdatePieRange = this.btnUpdatePieRange.bind(this);
  }

  componentDidMount() {
    this.loadDetail();
    this.initLanguage();
    this.initSelect();
  }

  findKeyValue(obj, key, value) {
    for (var i = 0; i < obj.length; i++) {
      if (obj[i][key] === value) {
        return obj[i];
      }
    }
  }

  handleAreaChange = (selectedOption) => {
    let cts = this.findKeyValue(area, "region", selectedOption.value).cities;
    this.setState({
      area: selectedOption.value,
      cities: JsonToKeyValue(cts, "city", "city"),
    });
    let ct = document.getElementById("select_city");
    ct.firstChild.firstChild.firstChild.innerHTML = "";
    let cm = document.getElementById("select_community");
    cm.firstChild.firstChild.firstChild.innerHTML = "";
  };

  handleCityChange = (selectedOption) => {
    if (selectedOption !== null) {
      let cts = this.findKeyValue(region, "city", selectedOption.value)
        .communities;
      this.setState({
        communities: JsonToKeyValue(cts, "community", "community"),
      });
      this.refreshChart(
        this.state.tk,
        selectedOption.value + ",",
        this.state.ls,
        this.state.st,
        this.state.ed
      );
      let cm = document.getElementById("select_community");
      cm.firstChild.firstChild.firstChild.innerHTML = "";
    }
  };

  handleCommunityChange = (selectedOption) => {
    if (selectedOption !== null) {
      this.refreshChart(
        this.state.tk,
        "," + selectedOption.value,
        this.state.ls,
        this.state.st,
        this.state.ed
      );
    }
  };

  render() {
    return (
      <div>
        <div id="region_city">
          <ReactSelect
            id="select_area"
            className="select_area"
            style={{ width: "100%" }}
            placeholder="请选择区域"
            options={JsonToKeyValue(area, "region", "region")}
            onChange={this.handleAreaChange}
            defaultValue={JsonToKeyValue(area, "region", "region")[0]}
            isSearchable={false}
          ></ReactSelect>

          <ReactSelect
            id="select_city"
            className="select_city"
            style={{ width: "100%" }}
            placeholder="请选择城市"
            options={this.state.cities}
            onChange={this.handleCityChange}
            defaultValue={JsonToKeyValue(region, "city", "city")[0]}
            isSearchable={false}
          ></ReactSelect>

          <ReactSelect
            id="select_community"
            className="select_community"
            style={{ width: "100%" }}
            placeholder="请选择社区"
            options={this.state.communities}
            onChange={this.handleCommunityChange}
            isSearchable={false}
          ></ReactSelect>
        </div>
        <br />
        <div
          id="year_range"
          // style={{ marginLeft: "10%", width: document.body.clientWidth }}
        >
          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue="3"
          >
            <FormControlLabel
              // style={{ width: "30%", float: "left" }}
              value="1"
              control={<Radio color="primary" />}
              label={this.state.lang["radio_1year"]}
              labelPlacement="bottom"
              onClick={this.radio1Year}
            />
            <FormControlLabel
              // style={{ width: "30%", float: "left" }}
              value="2"
              control={<Radio color="primary" />}
              label={this.state.lang["radio_2years"]}
              labelPlacement="bottom"
              onClick={this.radio2Years}
            />
            <FormControlLabel
              // style={{ width: "30%", float: "left" }}
              value="3"
              control={<Radio color="primary" />}
              label={this.state.lang["radio_3years"]}
              labelPlacement="bottom"
              onClick={this.radio3Years}
            />
          </RadioGroup>
        </div>
        <br />
        <div id="sold_leased">
          <RadioGroup
            // style={{ marginLeft: "20%" }}
            row
            aria-label="position"
            name="position"
            defaultValue="S"
          >
            <FormControlLabel
              // style={{ width: "40%", float: "left" }}
              value="S"
              control={<Radio color="primary" />}
              label={this.state.lang["radio_sold"]}
              labelPlacement="bottom"
              onClick={this.radioSold}
            />
            <FormControlLabel
              // style={{ width: "40%", float: "left" }}
              value="L"
              control={<Radio color="primary" />}
              label={this.state.lang["radio_leased"]}
              labelPlacement="bottom"
              onClick={this.radioLeased}
            />
          </RadioGroup>
        </div>
        <br />
        <div id="listing_type">
          <div className="groupbutton-scrolling-wrapper">
            <ButtonGroup size="sm">
              <Button variant="secondary" onClick={this.btnSelectALL}>
                {this.state.lang["all"]}
              </Button>
              <Button variant="secondary" onClick={this.btnSelectDetached}>
                {this.state.lang["detached"]}
              </Button>
              <Button variant="secondary" onClick={this.btnSelectSemi}>
                {this.state.lang["semi"]}
              </Button>
              <Button variant="secondary" onClick={this.btnSelectTown}>
                {this.state.lang["townhouse"]}
              </Button>
              <Button variant="secondary" onClick={this.btnSelectCondo}>
                {this.state.lang["condo"]}
              </Button>
              <Button variant="secondary" onClick={this.btnSelectOthers}>
                {this.state.lang["others"]}
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <br />
        <div
          id="chart_monthly"
          className="ChartMonthly"
          style={{
            marginLeft: "0.5rem",
            width: document.body.clientWidth - 16,
          }}
        >
          <div
            id="chart_listing"
            style={{ height: document.body.clientWidth }}
          ></div>
          <div
            id="chart_price"
            style={{ height: document.body.clientWidth }}
          ></div>
          <div
            id="chart_market"
            style={{ height: document.body.clientWidth }}
          ></div>
          <div
            id="chart_partition"
            style={{ width: "100%", height: document.body.clientWidth }}
          ></div>
          <div id="pie_range">
            <input
              id="input_st"
              type="text"
              style={{ width: "40%", float: "left", textAlign: "center" }}
              onChange={this.btnUpdatePieRange}
            />
            <div style={{ width: "19%", float: "left", textAlign: "center" }}>
              ~
            </div>
            <input
              id="input_ed"
              type="text"
              style={{ width: "40%", float: "left", textAlign: "center" }}
              onChange={this.btnUpdatePieRange}
            />
            <br />
            <br />
            <br />
          </div>
          <div
            id="chart_soldprice"
            style={{
              width: "100%",
              height: (document.body.clientWidth * 2) / 3,
            }}
          ></div>
          <div
            id="chart_asksprice"
            style={{
              width: "100%",
              height: (document.body.clientWidth * 2) / 3,
            }}
          ></div>
        </div>
        <div id="foot">
          <br />
          <br />
          <br />
          <p>Copyright @Yiju 2021.</p>
        </div>
      </div>
    );
  }

  async loadDetail() {
    try {
      var url = document.location.toString();
      let paras = url.split("?").splice(1, 1)[0].split("#")[0].split("=");
      if (paras.length < 1) {
        return;
      }
      let tk = paras[0];
      let bk = "Toronto,";
      let ls = "S";
      var cur = new Date();
      cur.setMonth(cur.getMonth() - 1);
      let ed = cur.getFullYear() + "-" + (cur.getMonth() + 1) + "-01";
      let st = cur.getFullYear() - 3 + "-" + (cur.getMonth() + 1) + "-01";
      this.refreshChart(tk, bk, ls, st, ed);

      this.setState({
        tk: tk,
        bk: bk,
        ls: ls,
        st: st,
        ed: ed,
      });
    } catch {}
  }

  refreshChart(tk, bk, ls, st, ed) {
    try {
      this.setState({
        bk: bk,
      });

      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";

      let param_dis =
        "\"{'tk':'" +
        tk +
        "','id':'" +
        bk +
        "','ls':'" +
        ls +
        "','dt':'" +
        ed +
        "'}\"";
      axios.post("/detail/pricedistribution", param_dis).then((res) => {
        this.setState({
          distrib: res.data,
        });
        this.PartByDistribution();
      });

      let param_dtl =
        "\"{'tk':'" +
        tk +
        "','id':'" +
        bk +
        "','ls':'" +
        ls +
        "','st':'" +
        st +
        "','ed':'" +
        ed +
        "'}\"";
      axios.post("/detail/monthlyreport", param_dtl).then((res) => {
        this.setState({
          reports: res.data,
        });
        this.initDefaultSection();
      });
    } catch {}
  }

  initLanguage() {
    if (navigator.language === "zh-CN") {
      this.setState({
        lang: zhj,
      });
    }
  }

  initSelect() {
    this.setState({
      area: "Toronto",
      cities: JsonToKeyValue(
        this.findKeyValue(area, "region", "Toronto").cities,
        "city",
        "city"
      ),
      communities: JsonToKeyValue(
        this.findKeyValue(region, "city", "Toronto").communities,
        "community",
        "community"
      ),
    });
  }

  initDefaultSection() {
    this.PartByNodeType(this.state.reports, "6");
    document.getElementById("input_st").value = this.state.st.substring(
      0,
      this.state.st.length - 3
    );
    document.getElementById("input_ed").value = this.state.ed.substring(
      0,
      this.state.ed.length - 3
    );
    this.PartByDateRange(this.state.reports, this.state.st, this.state.ed);
  }

  btnUpdatePieRange() {
    try {
      var st = document.getElementById("input_st").value;
      var sts = st.split("-");
      var ed = document.getElementById("input_ed").value;
      var eds = ed.split("-");
      if (new Date(sts[0], sts[1] - 1, 1) <= new Date(eds[0], eds[1] - 1, 1)) {
        this.PartByDateRange(this.state.reports, st, ed);
      }
    } catch {}
  }

  radio1Year() {
    var cur = new Date();
    cur.setMonth(cur.getMonth() - 1);
    let st = cur.getFullYear() - 1 + "-" + (cur.getMonth() + 1) + "-01";
    this.refreshChart(
      this.state.tk,
      this.state.bk,
      this.state.ls,
      st,
      this.state.ed
    );
    this.setState({
      st: st,
    });
  }

  radio2Years() {
    var cur = new Date();
    cur.setMonth(cur.getMonth() - 1);
    let st = cur.getFullYear() - 2 + "-" + (cur.getMonth() + 1) + "-01";
    this.refreshChart(
      this.state.tk,
      this.state.bk,
      this.state.ls,
      st,
      this.state.ed
    );
    this.setState({
      st: st,
    });
  }

  radio3Years() {
    var cur = new Date();
    cur.setMonth(cur.getMonth() - 1);
    let st = cur.getFullYear() - 3 + "-" + (cur.getMonth() + 1) + "-01";
    this.refreshChart(
      this.state.tk,
      this.state.bk,
      this.state.ls,
      st,
      this.state.ed
    );
    this.setState({
      st: st,
    });
  }

  radioSold() {
    this.refreshChart(
      this.state.tk,
      this.state.bk,
      "S",
      this.state.st,
      this.state.ed
    );
    this.setState({
      ls: "S",
    });
  }
  radioLeased() {
    this.refreshChart(
      this.state.tk,
      this.state.bk,
      "L",
      this.state.st,
      this.state.ed
    );
    this.setState({
      ls: "L",
    });
  }

  btnSelectDetached() {
    this.PartByNodeType(this.state.reports, "1");
  }

  btnSelectSemi() {
    this.PartByNodeType(this.state.reports, "2");
  }

  btnSelectTown() {
    this.PartByNodeType(this.state.reports, "3");
  }

  btnSelectCondo() {
    this.PartByNodeType(this.state.reports, "4");
  }

  btnSelectOthers() {
    this.PartByNodeType(this.state.reports, "5");
  }

  btnSelectALL() {
    this.PartByNodeType(this.state.reports, "6");
  }

  PartByNodeType(list, type) {
    var month = [];
    var soldL = [];
    var newL = [];
    var activeL = [];
    var soldDOM = [];
    var soldMP = [];
    var soldAP = [];
    var newMP = [];
    var newAP = [];
    var soldMax = [];
    var soldMin = [];
    var newMax = [];
    var newMin = [];
    var absorb = [];
    for (var i = 0; i < list.length; i++) {
      // === will check type. == only check value.
      if (list[i].NodeType == type) {
        month.push(list[i].Month.substring(0, 7));
        soldL.push(list[i].Sold);
        newL.push(list[i].New);
        activeL.push(list[i].Active);
        soldDOM.push(list[i].SoldAverageDom);
        soldMP.push(list[i].SoldMedianPrice);
        soldAP.push(list[i].SoldAveragePrice);
        newMP.push(list[i].NewMedianPrice);
        newAP.push(list[i].NewAveragePrice);
        soldMax.push(list[i].SoldMaxPrice);
        soldMin.push(list[i].SoldMinPrice);
        newMax.push(list[i].NewMaxPrice);
        newMin.push(list[i].NewMinPrice);
        if (list[i].New === null || list[i].New === 0) {
          absorb.push(100);
        } else {
          absorb.push(
            ((list[i].Sold / list[i].New) * 100).toString().substring(0, 5)
          );
        }
      }
    }

    // Color and XAxis(Year)
    // var colors = ['#d14a61', '#5793f3', '#f8f2b8', '#54b936', '#EB68CC', '#E8BBF2', '#FEFF82', '#DF61AA', '#FA7292', '#9AE9BC', '#675bba', '#36A1D9', '#32C5E8', '#f8f2b8', '#bc7768', '#36A1D9', '#32C5E8', '#65E1E3', '#FF6567', '#A093F2', '#8675E7', '#9BBCFF'];
    var years = month;

    // Listing LINE&BAR
    var listingTitle = [
      this.state.lang["chart1_0"],
      years[0] + "~" + years[years.length - 1],
    ];
    var chart1_1 = this.state.lang["chart1_1"];
    if (this.state.ls === "L") {
      chart1_1 = this.state.lang["chart1_1l"];
    }
    var listingLegendSeries = [
      chart1_1,
      this.state.lang["chart1_2"],
      this.state.lang["chart1_3"],
    ];
    var listingYAxis = [
      this.state.lang["chart1_4"],
      this.state.lang["chart1_5"],
    ];
    var listingData = [soldL, newL, soldDOM];
    var listingColor = ["#d14a61", "#5793f3", "#f8f2b8"];
    SetChartListing(
      listingTitle,
      listingLegendSeries,
      years,
      listingYAxis,
      listingData,
      listingColor,
      "chart_listing"
    );

    // Price LINE
    var priceTitle = [
      this.state.lang["chart2_0"],
      years[0] + "~" + years[years.length - 1],
    ];
    var chart2_1 = this.state.lang["chart2_1"];
    if (this.state.ls === "L") {
      chart2_1 = this.state.lang["chart2_2"];
    }
    var chart2_2 = this.state.lang["chart2_2"];
    if (this.state.ls === "L") {
      chart2_2 = this.state.lang["chart2_2l"];
    }
    var priceLegendSeries = [
      chart2_1,
      chart2_2,
      this.state.lang["chart2_3"],
      this.state.lang["chart2_4"],
    ];
    var priceData = [soldMP, soldAP, newMP, newAP];
    var priceColor = ["#d14a61", "#5793f3", "#54b936", "#ffbf00"];
    SetChartPrice(
      priceTitle,
      priceLegendSeries,
      years,
      priceData,
      priceColor,
      "chart_price"
    );

    // Market LINE
    var chart3_0 = this.state.lang["chart3_0"];
    if (this.state.ls === "L") {
      chart3_0 = this.state.lang["chart3_0l"];
    }
    var marketTitle = [chart3_0, years[0] + "~" + years[years.length - 1]];
    var marketData = absorb;
    var marketColor = ["#d14a61", "#5793f3", "#54b936"];
    SetChartMarket(marketTitle, years, marketData, marketColor, "chart_market");
  }

  PartByDateRange(list, mstart, mend) {
    var tms = mstart.split("-");
    var start = new Date(tms[0], tms[1] - 1, 1);
    var tme = mend.split("-");
    var end = new Date(tme[0], tme[1] - 1, 1);
    var range = [];
    for (var i = 0; i < list.length; i++) {
      var tmonth = list[i].Month.split("T")[0].split("-");
      var curMonth = new Date(tmonth[0], tmonth[1] - 1, 1);
      if (curMonth >= start && curMonth <= end) {
        range.push(list[i]);
      }
    }
    var fillData = [];
    for (var k = 1; k < 6; k++) {
      var typeSum = 0;
      for (var j = 0; j < range.length; j++) {
        if (range[j].NodeType === k) {
          typeSum += range[j].Sold;
        }
      }
      fillData.push(typeSum);
    }
    // Partition PIE
    var pieColors = [
      "#c99286",
      "#689ef4",
      "#76c75e",
      "#da6e81",
      "#5bd1ed",
      "#65E1E3",
    ];
    var chart4_0 = this.state.lang["chart4_0"];
    if (this.state.ls === "L") {
      chart4_0 = this.state.lang["chart4_0l"];
    }
    var pieTitle = [chart4_0, mstart + "~" + mend];
    var pieLegend = [
      this.state.lang["detached"],
      this.state.lang["semi"],
      this.state.lang["townhouse"],
      this.state.lang["condo"],
      this.state.lang["others"],
    ];
    var pieFillData = fillData;
    var pieData = [
      { name: pieLegend[0], value: pieFillData[0] },
      { name: pieLegend[1], value: pieFillData[1] },
      { name: pieLegend[2], value: pieFillData[2] },
      { name: pieLegend[3], value: pieFillData[3] },
      { name: pieLegend[4], value: pieFillData[4] },
    ];
    SetChartPartition(
      pieTitle,
      pieLegend,
      pieData,
      pieColors,
      "chart_partition"
    );
  }

  PartByDistribution() {
    var rg = this.state.ed.substring(0, this.state.ed.length - 3);
    var chart5_0 = this.state.lang["chart5_0"];
    if (this.state.ls === "L") {
      chart5_0 = this.state.lang["chart5_0l"];
    }
    var titles = [chart5_0, rg, this.state.lang["chart6_0"], rg];
    var distriColors = ["#d14a61", "#f8f2b8"];
    SetupBothDistribution(
      titles,
      this.state.distrib,
      distriColors,
      "chart_soldprice",
      "chart_asksprice"
    );
  }
}
