import React, { Component } from "react";
import { Button } from "react-bootstrap";
// import ReactDOM from 'react-dom';
import axios from "axios";
import Slider from "react-slick";
import {
  datahost,
  gesture,
  yijuhost,
  pic_host,
  pic_link,
} from "./function/Const.js";
import { priceSwitch } from "./function/Basic.js";
import { passParams } from "./function/Connector.js";
import enj from "./language/en.json";
import zhj from "./language/zh.json";
import "bootstrap/dist/css/bootstrap.min.css";
// import "../style/AppDetail.css";

export class AppHome extends Component {
  static displayName = AppHome.name;

  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      tk: null,
      news: [],
      featureList: [],
      newCondos: [],
      rentalList: [],
    };
  }

  componentDidMount() {
    this.loadData();
    this.initLanguage();
  }

  render() {
    let setting0 = {
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    let setting1 = {
      dots: false,
      infinite: true,
      speed: 300,
      // lazyLoad: true,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: true,
    };

    return (
      <div className="wrapper">
        <div className="wrapper_margin">
          <div className="property_searchsec">
            <div className="main_searchsec">
              <img
                src={require("./../images/search_icon.png").default}
                alt=""
              />
              <input
                type="text"
                className=""
                name=""
                placeholder="Input MLS, Address, City"
              />
            </div>
            <div className="main_selectsec">
              <select>
                <option>Toronto</option>
                {/* <option>Vancouver</option>
                <option>Calgary</option> */}
              </select>
            </div>
            {/* main_selectsec */}
            <div className="map_searchsec">
              <a href="#">
                <i className="fa fa-map-marked-alt"></i>
              </a>
            </div>
            {/* map_search */}
          </div>
          {/* property_searchsec */}

          <div className="banner_sec section_margin">
            <div className="slider single-item">
              <Slider {...setting0}>
                <div className="inner_bannersec">
                  <img
                    src={require("./../images/banner1.jpg").default}
                    alt=""
                  />
                </div>
                <div className="inner_bannersec">
                  <img
                    src={require("./../images/banner2.jpg").default}
                    alt=""
                  />
                </div>
                <div className="inner_bannersec">
                  <img
                    src={require("./../images/banner3.jpg").default}
                    alt=""
                  />
                </div>
              </Slider>
            </div>
            {/* slider */}
          </div>
          {/* banner_sec */}

          <div className="main_categories_sec section_margin">
            <div className="categorydet_sec">
              <ul>
                <li>
                  <Button onClick={() => passParams("listings", "")}>
                    <img
                      src={require("./../images/listinghome.png").default}
                      alt=""
                    />{" "}
                    <p>Listings</p>
                  </Button>
                </li>
                <li>
                  {/* <a href={gesture + yijuhost + "/condo"}> */}
                  <Button onClick={() => passParams("condos", "")}>
                    <img
                      src={require("./../images/newcondo.png").default}
                      alt=""
                    />{" "}
                    <p>New Condos</p>
                  </Button>
                </li>
                <li>
                  <Button onClick={() => passParams("rent", "")}>
                    <img
                      src={require("./../images/rent_home.png").default}
                      alt=""
                    />{" "}
                    <p>Rent</p>
                  </Button>
                </li>
                {/* <li><a href="#"><img src={require('./../images/assignment.png').default} alt=""/> <p>Assignment</p></a></li> */}
                <li>
                  <Button onClick={() => passParams("pricedrop", "")}>
                    <img
                      src={require("./../images/pricedrop.png").default}
                      alt=""
                    />{" "}
                    <p>Price Drop</p>
                  </Button>
                </li>
                {/* <li><a href="#"><img src={require('./../images/openhouse.png').default} alt=""/> <p>Open House</p></a></li> */}
                {/* <li><a href="#"><img src={require('./../images/arshow.png').default} alt=""/> <p>AR Showing</p></a></li> */}
                {/* <li><a href="#"><img src={require('./../images/growhouse.png').default} alt=""/> <p>Grow House</p></a></li> */}
                <li>
                  <Button onClick={() => passParams("soldhistory", "")}>
                    <img
                      src={require("./../images/soldhome.png").default}
                      alt=""
                    />{" "}
                    <p>Sold History</p>
                  </Button>
                </li>
              </ul>
              {/* <ul>
                <li>
                  <a
                    href={
                      gesture +
                      yijuhost +
                      "/aprg/sellers/evaluation.aspx?sid=188888"
                    }
                  >
                    <img
                      src={require("./../images/proevlaution.png").default}
                      alt=""
                    />{" "}
                    <p>Evaluation</p>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      gesture + yijuhost + "/aprg/list/mortcal.aspx?sid=188888"
                    }
                  >
                    <img
                      src={require("./../images/calculator.png").default}
                      alt=""
                    />{" "}
                    <p>Calculators</p>
                  </a>
                </li>
                <li>
                  <a href={gesture + yijuhost + "/agent"}>
                    <img
                      src={require("./../images/agent.png").default}
                      alt=""
                    />{" "}
                    <p>Agents</p>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      gesture + yijuhost + "/aprg/schoolinfo.aspx?sid=188888"
                    }
                  >
                    <img
                      src={require("./../images/school.png").default}
                      alt=""
                    />{" "}
                    <p>School</p>
                  </a>
                </li> */}
              {/* <li><a href="#"><img src={require('./../images/subway.png')} alt=""/> <p>Search By Subway</p></a></li> */}
              {/* <li>
                  <a href={gesture + yijuhost + "/commercial"}>
                    <img
                      src={require("./../images/commerical.png").default}
                      alt=""
                    />{" "}
                    <p>Commerical</p>
                  </a>
                </li>
              </ul> */}
            </div>
            {/* categorydet_sec */}
          </div>
          {/* main_categories_sec */}

          <div className="marketrend_sec section_margin">
            <Button onClick={() => passParams("marketreport")}>
              <h1>Market Reports</h1>
              <p>Latest Trends</p>
              <img src={require("./../images/toronto.png").default} alt="" />
            </Button>
          </div>
          {/* marketrend_sec */}

          <div className="news_sec section_margin">
            <div className="main_headingsec">
              <h1>NEWS</h1>
              <p>Latest Real Estate News</p>
            </div>
            {/* main_headingsec */}
            <div className="news_wrappersec">
              <div className="slider variable-width">
                <Slider {...setting1}>
                  {this.state.news.map(function (item) {
                    return (
                      <div key={item.ArticleId}>
                        <div className="newsdetail_sec">
                          <div className="newsimg_sec">
                            <img src={item.Image} alt="" />
                          </div>
                          {/* newsimg_sec */}
                          <div className="news_contsec">
                            <h2>{item.Title}</h2>
                            {/* <p>{item.Brief}</p> */}
                          </div>
                          {/* news_contsec */}
                        </div>
                        {/* newsdetail_sec */}
                      </div>
                    );
                  })}
                </Slider>
              </div>
              {/* slider */}
            </div>
            {/* innernews_sec */}
          </div>
          {/* news_sec */}

          <div className="update_secs section_margin">
            <div className="innerupdate_sec">
              <a
                href={
                  gesture +
                  yijuhost +
                  "/aprg/article/articlecat.aspx?sid=188888&cid=6362"
                }
              >
                <img
                  src={require("./../images/real_estate.png").default}
                  alt=""
                />
                <h2>
                  REAL ESTATE <br />
                  NEWS
                </h2>
              </a>
            </div>
            {/* innerupdate_sec */}
            <div className="innerupdate_sec">
              <a
                href={
                  gesture +
                  yijuhost +
                  "/aprg/article/articlecat.aspx?sid=188888&cid=6524"
                }
              >
                <img
                  src={require("./../images/canada_news.png").default}
                  alt=""
                />
                <h2>
                  CANADA <br />
                  NEWS
                </h2>
              </a>
            </div>
            {/* innerupdate_sec */}
            <div className="innerupdate_sec">
              <a
                href={
                  gesture +
                  yijuhost +
                  "/aprg/article/articlecat.aspx?sid=188888&cid=6448"
                }
              >
                <img src={require("./../images/columns.png").default} alt="" />
                <h2>COLUMNS</h2>
              </a>
            </div>
            {/* innerupdate_sec */}
          </div>
          {/* update_secs */}

          <div className="listings_sec section_margin">
            <div className="main_headingsec">
              <h1>FEATURED LISTINGS</h1>
              <p>Latest Real Estate News</p>
              <a href="#">
                See All <i className="fa fa-angle-double-right"></i>
              </a>
            </div>
            {/* main_headingsec */}

            <div className="listing_wrappersec">
              <div className="slider variable-width">
                <Slider {...setting1}>
                  {this.state.featureList.map(function (item) {
                    return (
                      <div key={item.I}>
                        <a href={item.link}>
                          <div className="inner_listingsec">
                            <div className="listing_imgsec">
                              <img src={item.pic} alt="" />
                            </div>
                            {/* listing_imgsec */}
                            <div className="listing_detsec">
                              <h2>${item.a}</h2>
                              <span>
                                <i className="fa fa-map-marker-alt"></i>
                                {item.c}
                              </span>
                              <ul>
                                <li>
                                  {" "}
                                  <i className="fa fa-bed"></i>{" "}
                                  <p>Bed : {item.a1}</p>
                                </li>
                                <li>
                                  {" "}
                                  <i className="fa fa-bath"></i>{" "}
                                  <p>Bath : {item.b1}</p>
                                </li>
                                <li>
                                  {" "}
                                  <i className="fa fa-ruler-combined"></i>{" "}
                                  <p>Parking : {item.c1}</p>
                                </li>
                              </ul>
                              <div className="property_status">
                                <button>For {item.e}</button>
                              </div>
                              {/* forsale */}
                            </div>
                            {/* listing_detsec */}
                          </div>
                          {/* inner_listingsec */}
                        </a>
                      </div>
                    );
                  })}
                </Slider>
              </div>
              {/* slider */}
            </div>
            {/* listing_wrappersec */}
          </div>
          {/* listings_sec */}

          <div className="listings_sec section_margin">
            <div className="main_headingsec">
              <h1>NEW CONDOS</h1>
              <p>Latest Presale Condos</p>
              <a href="#">
                See All <i className="fa fa-angle-double-right"></i>
              </a>
            </div>
            {/* main_headingsec */}
            <div className="listing_wrappersec">
              <div className="slider variable-width">
                <Slider {...setting1}>
                  {this.state.newCondos.map(function (item) {
                    return (
                      <div key={item.I}>
                        <a href={item.link}>
                          <div className="inner_listingsec">
                            <div className="listing_imgsec">
                              <img src={item.pic} alt="" />
                            </div>
                            {/* listing_imgsec */}
                            <div className="listing_detsec">
                              <h2>${item.a}</h2>
                              <span>
                                <i className="fa fa-map-marker-alt"></i>
                                {item.c}
                              </span>
                              <ul>
                                <li>
                                  {" "}
                                  <i className="fa fa-bed"></i>{" "}
                                  <p>Bed : {item.a1}</p>
                                </li>
                                <li>
                                  {" "}
                                  <i className="fa fa-bath"></i>{" "}
                                  <p>Bath : {item.b1}</p>
                                </li>
                                <li>
                                  {" "}
                                  <i className="fa fa-ruler-combined"></i>{" "}
                                  <p>Parking : {item.c1}</p>
                                </li>
                              </ul>
                              <div className="property_status">
                                <button>For {item.e}</button>
                              </div>
                              {/* forsale */}
                            </div>
                            {/* listing_detsec */}
                          </div>
                          {/* inner_listingsec */}
                        </a>
                      </div>
                    );
                  })}
                </Slider>
              </div>
              {/* slider */}
            </div>
            {/* listing_wrappersec */}
          </div>
          {/* listings_sec */}

          <div className="listings_sec section_margin">
            <div className="main_headingsec">
              <h1>Rental Listings</h1>
              <p>Latest listings for lease</p>
              <a href="#">
                See All <i className="fa fa-angle-double-right"></i>
              </a>
            </div>
            {/* main_headingsec */}
            <div className="listing_wrappersec">
              <div className="slider variable-width">
                <Slider {...setting1}>
                  {this.state.rentalList.map(function (item) {
                    return (
                      <div key={item.I}>
                        <a href={item.link}>
                          <div className="inner_listingsec">
                            <div className="listing_imgsec">
                              <img src={item.pic} alt="" />
                            </div>
                            {/* listing_imgsec */}
                            <div className="listing_detsec">
                              <h2>${item.a}</h2>
                              <span>
                                <i className="fa fa-map-marker-alt"></i>{" "}
                                {item.c}
                              </span>
                              <ul>
                                <li>
                                  {" "}
                                  <i className="fa fa-bed"></i>{" "}
                                  <p>Bed : {item.a1}</p>
                                </li>
                                <li>
                                  {" "}
                                  <i className="fa fa-bath"></i>{" "}
                                  <p>Bath : {item.b1}</p>
                                </li>
                                <li>
                                  {" "}
                                  <i className="fa fa-ruler-combined"></i>{" "}
                                  <p>Parking : {item.c1}</p>
                                </li>
                              </ul>
                              <div className="property_status">
                                <button>For {item.e}</button>
                              </div>
                              {/* forsale */}
                            </div>
                            {/* listing_detsec */}
                          </div>
                          {/* inner_listingsec */}
                        </a>
                      </div>
                    );
                  })}
                </Slider>
              </div>
              {/* slider */}
            </div>
            {/* main_headingsec */}
          </div>
        </div>
        {/* wrapper_margin */}
        <br />
        <br />
        <div>2019-2021 Yiju Copyright</div>
      </div>
    );
  }

  initLanguage() {
    if (navigator.language == "zh-CN") {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadData() {
    try {
      var url = document.location.toString();
      let paras = url.split("?").splice(1, 1)[0].split("#")[0].split("=");
      if (paras.length < 1) {
        return;
      }
      let tk = paras[0];
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";

      let param_news = "\"{'tp':'0;20;1','tk':'" + tk + "'}\"";
      axios.post("/article/yijunews", param_news).then((res) => {
        this.setState({ news: res.data });
      });

      let param_feature =
        "\"{'mt':'a;20;1;dF;u4,E,v7,A,x3;!,=,=,>,~;COM,S,Toronto,1000000,1|2|3','tk':'" +
        tk +
        "'}\"";
      axios.post("/app/list", param_feature).then((res) => {
        this.setState({ featureList: this.fillList(res.data) });
      });

      let param_condo =
        "\"{'mt':'a;20;1;dF;u4,E,v7,x3;!,=,=,~;COM,S,Toronto,4','tk':'" +
        tk +
        "'}\"";
      axios.post("/app/list", param_condo).then((res) => {
        this.setState({ newCondos: this.fillList(res.data) });
      });

      let param_rental =
        "\"{'mt':'a;20;1;F;u4,E,v7;!,=,=;COM,L,Toronto','tk':'" + tk + "'}\"";
      axios.post("/app/list", param_rental).then((res) => {
        this.setState({ rentalList: this.fillList(res.data) });
      });

      // save token
      this.setState({
        tk: tk,
      });
    } catch {}
  }

  fillList = (data) => {
    // let str_0 = hostname + "/appdet?tk=";
    // let str_1 = "&ngw=";
    let str_0 = gesture + document.location.host + "/listing?";
    let str_1 = "=";
    let str_2 = "&ngw=";
    data.forEach((element) => {
      // fill detail link
      let ngw_0 = "940682259,";
      let ngw_1 = ",";
      let dt = "2021-01-08 12:25:08";
      let ngw_2 = ",";
      let ngw_str = ngw_0 + element.I + ngw_1 + dt + ngw_2 + element.O;
      var ngw = window.btoa(ngw_str);
      ngw = ngw.replace("=", "~");
      element["link"] = str_0 + this.state.tk + str_1 + ngw;
      // fill picture link
      element["pic"] =
        gesture + pic_host + pic_link + this.state.tk + str_2 + ngw;
      // fix parking
      if (element["c1"] == null) {
        element["c1"] = 0;
      }
      // fix address
      element["c"] = element["c"].replaceAll(",", " ").replaceAll("|", ",");
      // fix price showing
      element["a"] = priceSwitch(element["a"]);
    });
    return data;
  };

  // priceSwitch(x) {
  //   var leftNum = x.toString();
  //   var result;
  //   var resultArray = new Array();
  //   if (leftNum.length > 3) {
  //     var i = true;
  //     while (i) {
  //       resultArray.push(leftNum.slice(-3));
  //       leftNum = leftNum.slice(0, leftNum.length - 3);
  //       if (leftNum.length < 4) {
  //         i = false;
  //       }
  //     }
  //     var sortArray = new Array();
  //     for (var j = resultArray.length - 1; j >= 0; j--) {
  //       sortArray.push(resultArray[j]);
  //     }
  //     result = leftNum + "," + sortArray.join(",");
  //   } else {
  //     result = x.toString();
  //   }
  //   return result;
  // }

  // jumpFromHome(param) {
  //   // listings
  //   if (param === "listings") {
  //     try {
  //       window.webkit.messageHandlers.jumpApp.postMessage(
  //         "mapSearch;YTtyO3M7Ozs="
  //       );
  //     } catch {}
  //     try {
  //       window.agentWeb.jumpApp("mapSearch;YTtyO3M7Ozs=");
  //     } catch {}
  //   }
  //   // condos
  //   else if (param === "condos") {
  //     try {
  //       window.webkit.messageHandlers.jumpApp.postMessage(
  //         "mapSearch;YTtyO3M7eDM7fjs0"
  //       );
  //     } catch {}
  //     try {
  //       window.agentWeb.jumpApp("mapSearch;YTtyO3M7eDM7fjs0");
  //     } catch {}
  //   }
  //   // rent
  //   else if (param === "rent") {
  //     try {
  //       window.webkit.messageHandlers.jumpApp.postMessage(
  //         "mapSearch;YTtyO2w7Ozs="
  //       );
  //     } catch {}
  //     try {
  //       window.agentWeb.jumpApp("mapSearch;YTtyO2w7Ozs=");
  //     } catch {}
  //   }
  //   // pricedrop
  //   else if (param === "pricedrop") {
  //     try {
  //       window.webkit.messageHandlers.jumpApp.postMessage(
  //         "mapSearch;YTtyO3M7Qjs9OzE="
  //       );
  //     } catch {}
  //     try {
  //       window.agentWeb.jumpApp("mapSearch;YTtyO3M7Qjs9OzE=");
  //     } catch {}
  //   }
  //   // soldhistory
  //   else if (param === "soldhistory") {
  //     try {
  //       window.webkit.messageHandlers.jumpApp.postMessage(
  //         "mapSearch;YztyO3M7dzcseDI7PSw+O1NsZCwyMDIxLTAxLTAx"
  //       );
  //     } catch {}
  //     try {
  //       window.agentWeb.jumpApp("mapSearch;YztyO3M7dzcseDI7PSw+O1NsZCwyMDIxLTAxLTAx");
  //     } catch {}
  //   }
  //   // marketreport
  //   else if (param === "marketreport") {
  //     try {
  //       window.webkit.messageHandlers.jumpApp.postMessage(
  //         "marketReport;emptyparams"
  //       );
  //     } catch {}
  //     try {
  //       window.agentWeb.jumpApp("marketReport;emptyparams");
  //     } catch {}
  //   } else {
  //     // DO NOTHING
  //   }
  // }
}
