import React from "react";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import { Button } from "react-bootstrap";
import { passParams } from "./function/Connector.js";
import axios from "axios";
import enj from "./language/en.json";
import zhj from "./language/zh.json";

export class EditorDemo extends React.Component {
  state = {
    editorState: null,
    link: null,
  };

  async componentDidMount() {
    // Assume here to get the editor content in html format from the server
    const htmlContent = "";
    // Use BraftEditor.createEditorState to convert html strings to editorState data needed by the editor
    this.setState({
      editorState: BraftEditor.createEditorState(htmlContent),
    });
  }

  submitContent = async () => {
    // Pressing ctrl + s when the editor has focus will execute this method
    // Before the editor content is submitted to the server, you can directly call editorState.toHTML () to get the HTML content
    const htmlContent = this.state.editorState.toHTML();
    // const result = await saveEditorContent(htmlContent);
    console.log(htmlContent);
  };

  handleEditorChange = (editorState) => {
    this.setState({ editorState });
  };

  render() {
    const { editorState } = this.state;

    return (
      <div className="my-component">
        <BraftEditor
          value={editorState}
          language="en"
          onChange={this.handleEditorChange}
          onSave={this.submitContent}
        />
        <Button
          id="pro-btn-mf"
          style={{ width: "98%" }}
          onClick={() =>
            passParams(
              "sharemedia",
              "aHR0cHM6Ly93d3cueWlqdWNhLmNvbS9hcHBob21lP1YtaFloMHJQNklyaEtkY2dLSXF2YVJ4V3RQNTBidmNId0xmVUYtalVOYTRLMF9JMGExRzJlaWNzdWUyeXJtRlk7WWlqdSBIb21lOwpUaGlzIGlzIGEgdGVzdCBwYWdlIGZvciBzaGFyaW5nLjtodHRwczovL3d3dy55aWp1Y2EuY29tL1lJSlUvc3RhdGljL21lZGlhL2Jhbm5lcjEuNGU0NGI2ZDAuanBn"
            )
          }
        >
          Preview
        </Button>

        <br />
        <br />
        <input
          id="inputLink"
          placeholder="URL link to load."
          onChange={(e) => {
            this.setState({
              link: e.target.value,
            });
          }}
        />
        <Button
          id="pro-btn-mf"
          style={{ width: "98%" }}
          onClick={() => this.loadRemoteArticle()}
        >
          Load Link
        </Button>
      </div>
    );
  }

  async loadRemoteArticle() {
    // let link = this.state.link;
    // console.log(link);
    // NEED TO APPROVE CORS PROBLEM
    let res = await axios.get("http://www.yiju.ca", { withCredentials: true });
    console.log(res);
    // axios.get("http://localhost:3000/newsdetail?SlnWml6doPy3NETX0B8zjx5LLFjin0mQnfSqnKrQaymyCv20HBp64NAJRkIgcqwn=59198").then((res) => {
    //   console.log(res.data);
    //   const htmlContent = res.data;
    //   // Use BraftEditor.createEditorState to convert html strings to editorState data needed by the editor
    //   this.setState({
    //     editorState: BraftEditor.createEditorState(htmlContent),
    //   });
    // });
  }
}
