export function passParams(keys, values) {
  if (keys === "sharemedia") {
    callClient("shareMedia", values);
  }
  // listings
  else if (keys === "listings") {
    callClient("mapSearch", "YTtyO3M7Ozs=");
  }
  // condos
  else if (keys === "condos") {
    callClient("mapSearch", "YTtyO3M7eDM7fjs0");
  }
  // rent
  else if (keys === "rent") {
    callClient("mapSearch", "YTtyO2w7Ozs=");
  }
  // pricedrop
  else if (keys === "pricedrop") {
    callClient("mapSearch", "YTtyO3M7Qjs9OzE=");
  }
  // soldhistory
  else if (keys === "soldhistory") {
    callClient("mapSearch", "YztyO3M7dzcseDI7PSw+O1NsZCwyMDIxLTAxLTAx");
  }
  // marketreport
  else if (keys === "marketreport") {
    callClient("marketReport", "");
  } else {
    // DO NOTHING
  }
}

function callClient(keys, values) {
  try {
    window.webkit.messageHandlers.jumpApp.postMessage(keys + ";" + values);
  } catch {}
  try {
    window.agentWeb.jumpApp(keys + ";" + values);
  } catch {}
}
