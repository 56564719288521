import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Slider from "react-slick";
import classnames from "classnames";
import ReactSelect from "react-select";
import axios from "axios";
import enj from "./language/en.json";
import zhj from "./language/zh.json";
import {
  gesture,
  findschoolhost,
  datahost,
  pic_host,
  pic_link,
} from "./function/Const.js";
import { ReadValue } from "./function/Basic.js";
import "bootstrap/dist/css/bootstrap.min.css";
// import "../style/AppDetail.css";

export class NewCondos extends Component {
  static displayName = NewCondos.name;

  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      tk: null,
      condos: [],
    };
  }

  componentDidMount() {
    this.loadDetail();
  }

  render() {
    let setting0 = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    let setting1 = {
      dots: false,
      infinite: true,
      speed: 300,
      // lazyLoad: true,
      slidesToShow: 1,
      centerMode: false,
      variableWidth: true,
    };

    return (
      <div className="wrapper">
        <div className="wrapper_margin">
          <div className="detail_contactsec section_margin">
            <div className="main_headingsec">
              <h1>Search New Condo Projects</h1>
            </div>
            {/* detail_headingsec */}
            <div className="detcontact_fieldsec">
              <div className="inputsel_iconsec">
                <i className="fas fa-building"></i>
                <input
                  type="text"
                  className="inputcont_field"
                  name=""
                  placeholder="Project"
                  required=""
                />
              </div>
              <div className="inputsel_iconsec">
                <i className="fas fa-city"></i>
                <input
                  type="text"
                  className="inputcont_field"
                  name=""
                  placeholder="City"
                  required=""
                />
              </div>
              <div className="inputsel_iconsec">
                <i className="fas fa-house-user"></i>
                <input
                  type="text"
                  className="inputcont_field"
                  name=""
                  placeholder="Builder"
                  required=""
                />
              </div>
              <div className="inputsel_iconsec">
                <i className="far fa-calendar-check"></i>
                <ReactSelect
                  id="occupancy"
                  className="inputcont_field"
                  style={{ width: "100%" }}
                  placeholder="Occupancy Date"
                  //   options={JsonToKeyValue(this.state.allTopic, "t", "k")}
                  //   onChange={this.handleAreaChange}
                  //   defaultValue={JsonToKeyValue(this.state.allTopic, "t")[0]}
                  isSearchable={false}
                ></ReactSelect>
              </div>
              <br />
              <Button className="btncont_field"> Search</Button>
            </div>
            {/* detcontact_fieldsec */}
          </div>
          {/* detail_contactsec */}

          <div className="listings_sec section_margin">
            <div className="main_headingsec">
              <h1>Condos Listings</h1>
              <p>Latest Condo Listings of the Builders</p>
            </div>
            {/* main_headingsec */}

            <div className="listing_wrappersec outer_condodetsec">
              <Slider {...setting1}>
                {this.state.condos.map(function (item) {
                  return (
                    <div key={item.I}>
                      <div className="inner_listingsec">
                        <div className="listing_imgsec">
                          <img src="images/condo_homes.png" alt="" />
                        </div>
                        {/* listing_imgsec */}
                        <div className="listing_detsec">
                          <h2>$12,00,999</h2>
                          <span>
                            <i className="fa fa-map-marker-alt"></i> Leisle
                            Street / Eglin Mills Rd E, Richmond Hill
                          </span>

                          <div className="developer_compsec">RICHLANDS</div>
                          <div className="developer_compnamesec">
                            <strong>Developers :</strong> Lifetime & Knightstone
                            Capital
                          </div>
                          <div className="occupancy_datesec">
                            <strong> Date : </strong> 15-Jan-2021
                          </div>
                        </div>
                        {/* listing_detsec */}
                      </div>
                      {/* inner_listingsec */}
                    </div>
                  );
                })}
              </Slider>
            </div>
            {/* listing_wrappersec */}
          </div>
          {/* listings_sec */}
        </div>
        {/* wrapper_margin */}
      </div>
    );
  }

  initLanguage() {
    if (navigator.language === "zh-CN") {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadDetail() {
    try {
      //   let url = document.location.toString();
      //   let paras = url.split("?").splice(1, 1)[0].split("&");
      //   let tk = paras[0].split("=")[1];
      //   let ngw = paras[1].split("=")[1]; // save token and 'ngw' params
      //   this.setState({ tk: tk, ngw: ngw });
      //   let decode = window.atob(ngw.replaceAll("~", "=")).split(","); // load detail information
      //   axios.defaults.baseURL = datahost;
      //   axios.defaults.headers.post["Content-Type"] = "application/json";
      //   let param = "\"{'tk':'" + tk + "','id':'" + decode[1] + "'}\"";
      //   axios.post("/detail/app", param).then((res) => {
      //   });
    } catch {}
  }
}
