export function ReadValue(key) {
  if (key === null || key === "null") {
    return "";
  } else {
    return key;
  }
}

export function CheckValue(key) {
  if (key === "Y") {
    // return '✔';
    return "Yes";
  } else {
    // return '✗';
    return "No";
  }
}

export function JsonToKeyValue(obj, key, val) {
  var re = [];
  for (var i = 0; i < obj.length; i++) {
    var kvobj = { value: obj[i][val], label: obj[i][key] };
    re.push(kvobj);
  }
  return re;
}

export function ShowHideDIV(div) {
  console.log(div);
  var st = document.getElementById(div);
  console.log(st);
  if (st !== null) {
    if (st.hidden === true) {
      st.hidden = false;
    } else {
      st.hidden = true;
    }
  }
}

export function priceSwitch(x) {
  var leftNum = x.toString();
  var result;
  var resultArray = new Array();
  if (leftNum.length > 3) {
    var i = true;
    while (i) {
      resultArray.push(leftNum.slice(-3));
      leftNum = leftNum.slice(0, leftNum.length - 3);
      if (leftNum.length < 4) {
        i = false;
      }
    }
    var sortArray = new Array();
    for (var j = resultArray.length - 1; j >= 0; j--) {
      sortArray.push(resultArray[j]);
    }
    result = leftNum + "," + sortArray.join(",");
  } else {
    result = x.toString();
  }
  return result;
}

// export
// function findKey (obj,value, compare = (a, b) => a === b) {  return Object.keys(obj).find(k => compare(obj[k], value))
// }

// export function testtest(obj, key, value) {
//   for (var i = 0; i < obj.length; i++) {
//     if (obj[i][key] === value) {
//       return obj[i];
//     }
//   }
// }
