import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Toast,
  ToastHeader,
  ToastBody,
} from "react-bootstrap";
import axios from "axios";
import { datahost } from "./function/Const.js";
import enj from "./language/en.json";
import zhj from "./language/zh.json";
import "bootstrap/dist/css/bootstrap.min.css";
// import "../style/AppDetail.css";

export class QrUpload extends Component {
  static displayName = QrUpload.name;

  state = {
    lang: enj,
    tk: null,
    hdimage: null,
    qrimage: null,
    title: null,
    compy: null,
    intro: null,
  };

  componentDidMount() {
    this.initLanguage();
    this.initToast();
    this.loadDetail();
  }

  render() {
    var { hdimage, qrimage } = this.state;
    var hdImagePreview = null;
    if (hdimage) {
      hdImagePreview = (
        <label htmlFor="avatarFor">
          <img style={{ width: "80px", height: "80px" }} src={hdimage} />
        </label>
      );
    }
    var qrImagePreview = null;
    if (qrimage) {
      qrImagePreview = (
        <label htmlFor="avatarFor">
          <img style={{ width: "80px", height: "80px" }} src={qrimage} />
        </label>
      );
    }

    return (
      <div className="wrapper">
        <div className="detail_contactsec section_margin">
          <div className="main_headingsec">
            <h1>Update Namecard</h1>
          </div>
          <div className="detcontact_fieldsec">
            <input
              type="text"
              className="inputcont_field"
              name=""
              placeholder="First Name *"
              required=""
              id="inputFirstname"
              disabled="disabled"
            />
            <input
              type="text"
              className="inputcont_field"
              name=""
              placeholder="Last Name *"
              required=""
              id="inputLastname"
              disabled="disabled"
            />
            <input
              type="text"
              className="inputcont_field"
              name=""
              placeholder="Your Email Address *"
              required=""
              id="inputEmail"
              disabled="disabled"
            />
            <input
              type="text"
              className="inputcont_field"
              name=""
              placeholder="Your Phone Number (Required)"
              required=""
              id="inputPhone"
              disabled="disabled"
            />
            <div id="hdToast" className="p-3 bg-danger my-2 rounded">
              <Toast>
                <ToastHeader>Can not read image.</ToastHeader>
                <ToastBody>Image size must less than 2MB.</ToastBody>
              </Toast>
            </div>
            <span className="file_input">Personal Image</span>
            <div id="hdImageBlock">{hdImagePreview}</div>
            <input
              type="file"
              className="inputcont_field"
              name=""
              required=""
            />
            <small className="file_smalldesc">
              This is the personal image to let people know you. Can put your
              LOGO also.
            </small>
            <input
              type="text"
              className="inputcont_field"
              name=""
              placeholder="Title *"
              required=""
              id="inputTitle"
              maxLength="100"
              placeholder="Your Title"
              onChange={(e) => {
                this.setState({
                  title: e.target.value,
                });
              }}
            />
            <input
              type="text"
              className="inputcont_field"
              name=""
              placeholder="Company *"
              required=""
              id="inputCompany"
              placeholder="Company or Brokage"
              onChange={(e) => {
                this.setState({
                  compy: e.target.value,
                });
              }}
            />
            <div id="qrToast" className="p-3 bg-danger my-2 rounded">
              <Toast>
                <ToastHeader>Can not read image.</ToastHeader>
                <ToastBody>Image size must less than 2MB.</ToastBody>
              </Toast>
            </div>
            <span className="file_input">QR Code</span>
            <div id="qrImageBlock">{qrImagePreview}</div>
            <input
              type="file"
              className="inputcont_field"
              name=""
              required=""
            />
            <small className="file_smalldesc">
              This is the QR namecard image, help people to know you when
              scanning this picture.
            </small>
            <textarea
              className="textareacont_field"
              placeholder="Introduction"
              className=""
              maxLength="300"
              id="inputIntroduction"
              placeholder="Introduce yourself within 300 words"
              onChange={(e) => {
                this.setState({
                  intro: e.target.value,
                });
              }}
            ></textarea>
            <button className="btncont_field">
              <i className="fas fa-user-tie"></i> Submit Details
            </button>
          </div>
        </div>
      </div>
    );
  }

  initLanguage() {
    if (navigator.language === "zh-CN") {
      this.setState({
        lang: zhj,
      });
    }
  }

  handleImageChangeHD(e) {
    e.preventDefault();
    var reader = new FileReader();
    var file = e.target.files[0];
    if (file != undefined) {
      if (file.size < 1024 * 1024 * 2) {
        document.getElementById("hdImageBlock").innerHTML = "";
        reader.onloadend = () => {
          // console.log('filename - ', file);
          // console.log('imagedata: ', reader.result);
          this.setState({
            hdfile: file,
            hdimage: reader.result,
          });
        };
        reader.readAsDataURL(file);
        document.getElementById("hdToast").style.display = "none";
      } else {
        document.getElementById("hdToast").style.display = "block";
      }
    }
  }

  handleImageChangeQR(e) {
    e.preventDefault();
    var reader = new FileReader();
    var file = e.target.files[0];
    if (file != undefined) {
      if (file.size < 1024 * 1024 * 2) {
        document.getElementById("qrImageBlock").innerHTML = "";
        reader.onloadend = () => {
          // console.log('filename - ', file);
          // console.log('imagedata: ', reader.result);
          this.setState({
            qrfile: file,
            qrimage: reader.result,
          });
        };
        reader.readAsDataURL(file);
        document.getElementById("qrToast").style.display = "none";
      } else {
        document.getElementById("qrToast").style.display = "block";
      }
    }
  }

  initToast() {
    document.getElementById("hdToast").style.display = "none";
    document.getElementById("qrToast").style.display = "none";
  }

  async loadDetail() {
    try {
      let url = document.location.toString();
      let tk = url.split("?")[1];
      this.setState({ tk: tk });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param = "\"{'tk':'" + tk + "'}\"";
      console.log(param);
      axios.post("/user/namecard", param).then((res) => {
        console.log(res.data);
        document.getElementById("inputFirstname").value = res.data.Firstname;
        document.getElementById("inputLastname").value = res.data.Lastname;
        document.getElementById("inputEmail").value = res.data.Email;
        document.getElementById("inputPhone").value = res.data.Phone;
        document.getElementById("inputTitle").value = res.data.Title;
        document.getElementById("inputCompany").value = res.data.Company;
        document.getElementById("inputIntroduction").value =
          res.data.Introduction;
        document.getElementById("hdImageBlock").innerHTML =
          '<img src="' + res.data.Headimage + '"/>';
        document.getElementById("qrImageBlock").innerHTML =
          '<img src="' + res.data.Qrimage + '"/>';
        this.setState({
          tit: res.data.Title,
          compy: res.data.Company,
          intro: res.data.Introduction,
        });
      });
    } catch {}
  }

  uploadSubmit() {
    try {
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param =
        "\"{'tk':'" +
        this.state.tk +
        "','tit':'" +
        this.state.title +
        "','com':'" +
        this.state.compy +
        "','intro':'" +
        this.state.intro +
        "','hd':'" +
        this.state.hdimage +
        "','qr':'" +
        this.state.qrimage +
        "',}\"";
      axios.post("/user/updtcard", param).then((res) => {
        console.log(res.data);
      });
    } catch {}
  }
}
