import React, { Component } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import enj from "./language/en.json";
import zhj from "./language/zh.json";
import { datahost } from "./function/Const.js";
import "bootstrap/dist/css/bootstrap.min.css";

export class ArticleDetail extends Component {
  static displayName = ArticleDetail.name;

  state = {
    lang: enj,
    tk: null,
    article: null,
  };

  componentDidMount() {
    this.loadDetail();
    this.initLanguage();
  }

  render() {
    return (
      <div id="newsDetail">
        <div>
          <Button
            id="pro-btn-mf"
            style={{ width: "98%" }}
            onClick={() => this.BackClick()}
          >
            Back
          </Button>
        </div>
        <div id="newsContent"></div>
      </div>
    );
  }

  initLanguage() {
    if (navigator.language === "zh-CN") {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadDetail() {
    try {
      let url = document.location.toString();
      let paras = url.split("?")[1];
      let tk = paras.split("=")[0];
      let article = paras.split("=")[1];
      this.setState({ tk: tk, article: article });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param = "\"{'tk':'" + tk + "','id':'" + article + "'}\"";
      axios.post("/article/yijudet", param).then((res) => {
        var content = res.data.Content;
        console.log(content);
        if (content != undefined) {
          content = content.replaceAll("/aprg", "https://www.yiju.ca/aprg");
          content = content.replaceAll(
            "/eadf26jst",
            "https://www.yiju.ca/eadf26jst"
          );
          document.getElementById("newsContent").innerHTML = content;
        }
      });
    } catch {}
  }

  BackClick() {
    window.history.go(-1);
  }
}
