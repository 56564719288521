// import echarts from "echarts/lib/echarts";
// import * as echarts from "echarts/lib/echarts";
import * as echarts from "echarts/dist/echarts.js";
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/title";
import "echarts/lib/component/legend";
import "echarts/lib/component/visualMap";

export function SetChartListing(
  mtitle,
  mlegendseries,
  xaxis,
  yaxis,
  mdata,
  mcolor,
  container
) {
  var dom = document.getElementById(container);
  var myChart = echarts.init(dom);
  var option = {
    color: mcolor,
    title: {
      text: mtitle[0],
      subtext: mtitle[1],
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#999",
        },
      },
    },
    legend: {
      top: 50,
      left: "center",
      data: mlegendseries,
    },
    grid: {
      left: 50,
      top: 120,
      bottom: 60,
    },
    xAxis: [
      {
        type: "category",
        axisTick: {
          alignWithLabel: true,
        },
        axisLine: {
          onZero: false,
        },
        data: xaxis,
      },
    ],
    yAxis: [
      {
        type: "value",
        name: yaxis[0],
        axisLabel: {
          formatter: "{value}",
        },
      },
      {
        type: "value",
        name: yaxis[1],
        axisLabel: {
          formatter: "{value}",
        },
      },
    ],
    series: [
      {
        name: mlegendseries[0],
        type: "line",
        smooth: true,
        data: mdata[0],
      },
      {
        name: mlegendseries[1],
        type: "line",
        smooth: true,
        data: mdata[1],
      },
      {
        name: mlegendseries[2],
        type: "bar",
        yAxisIndex: 1,
        data: mdata[2],
      },
    ],
  };
  if (option && typeof option === "object") {
    myChart.setOption(option, true);
  }
}

export function SetChartPrice(
  mtitle,
  mlegendseries,
  xaxis,
  mdata,
  mcolor,
  container
) {
  var dom = document.getElementById(container);
  var myChart = echarts.init(dom);
  var option = {
    color: mcolor,
    title: {
      text: mtitle[0],
      subtext: mtitle[1],
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#999",
        },
      },
    },
    grid: {
      left: 60,
      top: 120,
      bottom: 60,
    },
    legend: {
      top: 50,
      left: "center",
      data: mlegendseries,
    },
    xAxis: [
      {
        type: "category",
        axisTick: {
          alignWithLabel: true,
        },
        axisLine: {
          onZero: false,
        },
        data: xaxis,
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        name: mlegendseries[0],
        type: "line",
        smooth: true,
        data: mdata[0],
      },
      {
        name: mlegendseries[1],
        type: "line",
        smooth: true,
        data: mdata[1],
      },
      {
        name: mlegendseries[2],
        type: "line",
        smooth: true,
        data: mdata[2],
      },
      {
        name: mlegendseries[3],
        type: "line",
        smooth: true,
        data: mdata[3],
      },
    ],
  };
  if (option && typeof option === "object") {
    myChart.setOption(option, true);
  }
}

export function SetChartMarket(mtitle, xaxis, mdata, mcolor, container) {
  var dom = document.getElementById(container);
  var myChart = echarts.init(dom);
  var option = {
    color: mcolor,
    title: {
      text: mtitle[0],
      subtext: mtitle[1],
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      left: 40,
      top: 80,
      bottom: 60,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: xaxis,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: "{value}%",
      },
      axisPointer: {
        snap: true,
      },
    },
    visualMap: {
      show: false,
      dimension: 1,
      pieces: [
        {
          lte: 40,
          color: mcolor[2],
        },
        {
          gt: 40,
          lte: 60,
          color: mcolor[1],
        },
        {
          gt: 60,
          color: mcolor[0],
        },
      ],
    },
    series: [
      {
        name: mtitle[0],
        type: "line",
        smooth: true,
        data: mdata,
        markArea: {
          data: [
            [
              {
                name: "HIGH",
                yAxis: "40%",
              },
              {
                yAxis: "60%",
              },
            ],
          ],
        },
      },
    ],
  };
  if (option && typeof option === "object") {
    myChart.setOption(option, true);
  }
}

export function SetChartPartition(mtitle, mlegend, mdata, mcolor, container) {
  var dom = document.getElementById(container);
  var myChart = echarts.init(dom);
  var option = {
    color: mcolor,
    title: {
      text: mtitle[0],
      subtext: mtitle[1],
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      top: 50,
      left: "center",
      data: mlegend,
    },
    series: [
      {
        name: mtitle[0] + mtitle[1],
        type: "pie",
        radius: "50%",
        center: ["50%", "60%"],
        selectedMode: "single",
        data: mdata,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  if (option && typeof option === "object") {
    myChart.setOption(option, true);
  }
}

export function SetChartDistribution(mcolor, mtitle, xaxis, mdata, container) {
  var dom = document.getElementById(container);
  var myChart = echarts.init(dom);
  var option = {
    color: mcolor,
    title: {
      text: mtitle[0],
      subtext: mtitle[1],
    },
    tooltip: {
      trigger: "axis",
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      left: 60,
      top: 80,
      bottom: 60,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: xaxis,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: mdata,
        smooth: true,
        type: "line",
        color: mcolor[0],
        areaStyle: {
          color: mcolor[1],
        },
      },
    ],
  };
  if (option && typeof option === "object") {
    myChart.setOption(option, true);
  }
}

export function SetupBothDistribution(titles, price, mcolor, div0, div1) {
  // var titles = ['Sold Price Distribution (recent 6 months)','test', 'Asking Price Distribution (recent 6 months)','test'];
  // var colors = ['#d14a61', '#5793f3', '#f8f2b8', '#54b936', '#EB68CC', '#E8BBF2', '#FEFF82', '#DF61AA'];
  var soldasks = price.split("#");
  soldasks[0] = "0;" + soldasks[0] + ";∞;0";

  var soldL = soldasks[0].split(";");
  var soldX = [];
  var soldP = [];
  for (var i = 0; i < soldL.length; i++) {
    if (i % 2 === 0) {
      if (soldL[i][0] === "-") {
        soldL[i].substring(1, soldL[i].length - 1);
      }
      if (soldL[i] === "∞") {
        soldX.push(soldL[i]);
      } else {
        if (soldL[i].length > 4) {
          soldX.push(soldL[i].substring(0, soldL[i].length - 3) + "k");
        } else {
          soldX.push(soldL[i]);
        }
      }
    } else {
      soldP.push(soldL[i]);
    }
  }
  var soldTitle = [titles[0], titles[1]];
  SetChartDistribution(mcolor, soldTitle, soldX, soldP, div0);

  soldasks[1] = "0;" + soldasks[1] + ";∞;0";
  var asksL = soldasks[1].split(";");
  var asksX = [];
  var asksP = [];
  for (var j = 0; j < asksL.length; j++) {
    if (j % 2 === 0) {
      if (asksL[j] === "∞") {
        asksX.push(asksL[j]);
      } else {
        if (asksL[j].length > 4) {
          asksX.push(asksL[j].substring(0, asksL[j].length - 3) + "k");
        } else {
          asksX.push(asksL[j]);
        }
      }
    } else {
      asksP.push(asksL[j]);
    }
  }
  var asksTitle = [titles[2], titles[3]];
  SetChartDistribution(mcolor, asksTitle, asksX, asksP, div1);
}

export function SetChartCircle(mtitle, mlegend, mdata, mcolor, container) {
  var dom = document.getElementById(container);
  var myChart = echarts.init(dom);
  var option = {
    color: mcolor,
    title: {
      text: mtitle,
    },
    // tooltip: {
    //   trigger: "item",
    //   formatter: "{a} <br/>{b} : {c} ({d}%)",
    // },
    // legend: {
    //   bottom: -60,
    //   left: "center",
    //   data: mlegend,
    // },
    series: [
      {
        name: mtitle,
        type: "pie",
        radius: ["60%", "90%"],
        // center: ["50%", "60%"],
        selectedMode: "single",
        data: mdata,
        // hoverOffset: 16,
        // avoidLabelOverlap: false,
        label: {
          normal: {
            formatter: "",
            show: true,
            position: "center",
            rich: { t: { color: "black", fontSize: 16 } },
          },
          emphasis: {
            formatter: ["\n{x|{d}%}", "{y|{c}}", "{z|{b}}"].join("\n"),
            show: true,
            textStyle: { fontSize: "30", fontWeight: "bold" },
            rich: {
              x: { color: "red", fontSize: 30, fontWeight: "bold" },
              y: { color: "blue", fontSize: 18 },
              z: { color: "black", fontSize: 14 },
            },
          },
        },
        labelLine: { normal: { show: false } },
      },
    ],
  };

  if (option && typeof option === "object") {
    myChart.setOption(option, true);
  }
}
