import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Slider from "react-slick";
import classnames from "classnames";
import ReactSelect from "react-select";
import axios from "axios";
import enj from "./language/en.json";
import zhj from "./language/zh.json";
import {
  gesture,
  findschoolhost,
  datahost,
  pic_host,
  pic_link,
} from "./function/Const.js";
import { ReadValue } from "./function/Basic.js";
import "bootstrap/dist/css/bootstrap.min.css";
// import "../style/AppDetail.css";

export class S_Article extends Component {
  static displayName = S_Article.name;

  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      tk: null,
      condos: [],
    };
  }

  componentDidMount() {
    this.loadDetail();
  }

  render() {
    return (
      <div className="wrapper">
        <div className="wrapper_margin" id="inject"></div>
      </div>
    );
  }

  initLanguage() {
    if (navigator.language === "zh-CN") {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadDetail() {
    try {
      let ij = document.getElementById("inject");
      ij.outerHTML =
        '<ul><li>12431253465awefawefawef</li></ul><div class="media-wrap image-wrap"><img class="media-wrap image-wrap" src="https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8aHVtYW58ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80"/></div><p style="text-align:left;text-indent:2em;" size="0" _root="undefined" __ownerID="undefined" __hash="undefined" __altered="false"><span style="letter-spacing:4px">awefawefawefzxcvzxcv<span style="letter-spacing:0px"><strong>awefawefawefasdfasaw</strong><em><strong>efawefawefasdfa</strong>wefawefawefasdvasdfawefawe</em>fwe<u>fawefawfcbdghkgyukuilhuilhuilh</u>ujik,ghuykguyik<sup>gyukgyukgyukgyukgyukgyukgyuk</sup>gyukg<sub>yukgyukgyukgyukgljiokl,.ikguiykgyuk</sub>yukgyukgyukgyukgyukgyukgyukgyukgyukgyukmn,bnm,bnm,<sub>vbhj,bhj,y,bnm,bnm,</sub>bnm,bnm,bnm,kjg,</span></span>❤️😇😌dtyjdtyjdtjy</p><p>fawef</p><p>这是什么玩意儿</p><h1>aweffeahrtshrth</h1>';
    } catch {}
  }
}
