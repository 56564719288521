import React, { Component } from "react";
import create360Viewer from "360-image-viewer";
import dragDrop from "drag-drop";

export class PanoramaView extends Component {
  static displayName = PanoramaView.name;

  componentDidMount() {
    this.setup();
  }

  render() {
    return (
      <div className="AppVirtualTour">
        <canvas id="canvas"></canvas>
        <div class="info">
          <div class="hr"></div>
          <p>Drop an equirectangular JPG or PNG here to view it in 360º</p>
        </div>
        <div id="drop-region"></div>
        {/* <div id="drop-region" style="display: none;"></div> */}
        {/* <script src="bundle.js"></script> */}
      </div>
    );
  }

  setup() {
    let dropRegion = document.querySelector("#drop-region");

    // Get a canvas of some sort, e.g. fullscreen or embedded in a site
    const canvas = this.createCanvas({
      canvas: document.querySelector("#canvas"),
      // without this, the canvas defaults to full-screen
      // viewport: [ 20, 20, 500, 256 ]
    });

    // Get the max image size possible
    // const imageUrl = this.getImageURL();
    const imageUrl = require("./../pano/2.jpg").default;
    // console.log(imageUrl);

    // whether to always rotate the view
    const autoSpin = false;

    // Load your image
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      // Setup the 360 viewer
      const viewer = create360Viewer({
        image: image,
        canvas: canvas,
      });

      this.setupDragDrop(canvas, viewer);

      // Start canvas render loop
      viewer.start();

      viewer.on("tick", (dt) => {
        if (autoSpin && !viewer.controls.dragging) {
          viewer.controls.theta -= dt * 0.00005;
        }
      });
    };
  }

  // Utility to create a device pixel scaled canvas
  createCanvas(opt = {}) {
    // default to full screen (no width/height specified)
    const viewport = opt.viewport || [0, 0];

    const canvas = opt.canvas || document.createElement("canvas");
    canvas.style.position = "absolute";
    canvas.style.top = `${viewport[0]}px`;
    canvas.style.left = `${viewport[1]}px`;

    // Resize the canvas with the proper device pixel ratio
    const resizeCanvas = () => {
      // default to fullscreen if viewport width/height is unspecified
      const width =
        typeof viewport[2] === "number" ? viewport[2] : window.innerWidth;
      const height =
        typeof viewport[3] === "number" ? viewport[3] : window.innerHeight;
      const dpr = window.devicePixelRatio;
      canvas.width = width * dpr;
      canvas.height = height * dpr;
      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;
    };

    // Ensure the grab cursor appears even when the mouse is outside the window
    const setupGrabCursor = () => {
      canvas.addEventListener("mousedown", () => {
        document.documentElement.classList.remove("grabbing");
        document.documentElement.classList.add("grabbing");
      });
      window.addEventListener("mouseup", () => {
        document.documentElement.classList.remove("grabbing");
      });
    };

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();
    setupGrabCursor();
    return canvas;
  }

  getImageURL() {
    // Choose a large texture size based on our GPU
    const maxTextureSize = 2048; //getMaxTextureSize();
    let imageUrl = "pano_2048.jpg";
    if (maxTextureSize >= 7000) imageUrl = "pano_7000.jpg";
    else if (maxTextureSize >= 4096) imageUrl = "pano_4096.jpg";
    return imageUrl;
  }

  setupDragDrop(canvas, viewer) {
    let dropRegion = document.querySelector("#drop-region");
    dragDrop(canvas, {
      onDragEnter: () => {
        dropRegion.style.display = "";
      },
      onDragLeave: () => {
        dropRegion.style.display = "none";
      },
      onDrop: (files) => {
        var img = new Image();
        img.onload = () => {
          viewer.texture(img);
        };
        img.onerror = () => {
          alert("Could not load image!");
        };
        img.crossOrigin = "Anonymous";
        img.src = URL.createObjectURL(files[0]);
      },
    });
  }
}
