import React, { Component } from "react";
import axios from "axios";
import { datahost } from "../function/Const.js";

export class WalkScore extends Component {
  static displayName = WalkScore.name;

  constructor(props) {
    super(props);
    this.state = {
      wskey: null,
    };
  }

  componentDidMount() {
    this.createWalkScore();

    // window.onload = function () {
    //   let footer = document.getElementById("ws-footer");
    //   footer.remove();
    // };

    // setTimeout(function () {
    //   let footer = document.getElementById("ws-street");
    //   footer.remove();
    // }, 3000);
  }

  render() {
    return (
      <div>
        <div id="ws-walkscore-tile"></div>
      </div>
    );
  }

  importWalkSchoolJS() {
    let script = document.querySelector("#script");
    if (script) {
      return;
    }
    script = document.createElement("script");
    script.id = "script_import";
    script.src = "https://www.walkscore.com/tile/show-walkscore-tile.php";
    document.querySelector("body").appendChild(script);
  }

  removeFooter() {
    let footer = document.getElementById("ws-street");
    footer.remove();
  }

  async createWalkScore() {
    var url = document.location.toString();
    let paras = url.split("?").splice(1, 1)[0].split("#")[0].split("=");
    if (paras.length < 2) {
      return;
    }
    let tk = paras[0];
    let address = window.atob(paras[1].replaceAll("~", "="));
    let style = "tall";
    let width = window.screen.width;
    let height = width * 1.5;

    axios.defaults.baseURL = datahost;
    axios.defaults.headers.post["Content-Type"] = "application/json";

    let param = "\"{'tk':'" + tk + "'}\"";
    axios.post("/detail/walkscore", param).then((res) => {
      this.setState({
        wskey: res.data,
      });
    });

    const jsCode =
      "var ws_wsid = '" +
      this.state.wskey +
      "'; var ws_address = '" +
      address +
      "'; var ws_format = '" +
      style +
      "'; var ws_width = '" +
      width +
      "'; var ws_height = '" +
      height +
      "';";
    let script = document.querySelector("#script");
    if (script) {
      return;
    }
    script = document.createElement("script");
    script.id = "script_init";
    script.innerHTML = jsCode;
    document.querySelector("body").appendChild(script);

    this.importWalkSchoolJS();
  }
}
