import React, { Component } from "react";
import { Button, Tabs, Tab } from "react-bootstrap";
import Slider from "react-slick";
import classnames from "classnames";
import axios from "axios";
import enj from "./language/en.json";
import zhj from "./language/zh.json";

import { datahost } from "./function/Const.js";
import {
  initImageLinksSharing,
  SetupPageValues,
  // createLog,
  createHistory,
  createCommunityChartSharing,
  createCensusDataSharing,
  createWalkScoreSharing,
  createSchoolReport,
  fixFrameHeight,
  addAccordionClick,
  MeterFeetClick,
} from "./function/Detail.js";
import "bootstrap/dist/css/bootstrap.min.css";

export class D_ShDtl extends Component {
  static displayName = D_ShDtl.name;

  constructor(props) {
    super(props);
    this.state = {
      lang: enj,
      sh: null,
      dtl: null,
      walkscore_loaded: false,
      school_loaded: false,
      community_loaded: false,
      census_loaded: false,
      images: [],
    };
  }

  componentDidMount() {
    this.loadDetail();
    this.initLanguage();
    addAccordionClick();
  }

  render() {
    let setting0 = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="AppListing">
        <div className="wrapper">
          <div className="wrapper_margin">
            <div className="property_locationsec" hidden={true}>
              <p>
                <i className="fa fa-map-marker-alt"></i>
                <i id="address"></i>
              </p>
            </div>
            {/* property_locationsec */}

            <div className="detail_banner_sec">
              <div className="slider single-item">
                <Slider {...setting0} id="testing">
                  {this.state.images.map(function (item) {
                    return (
                      <div key={item.ngw}>
                        <div className="inner_bannersec">
                          <img src={item.link} alt="" />
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
              {/* slider */}
            </div>
            {/* banner_sec */}

            <div className="proprice_infosec section_margin">
              <div className="property_pricesec">
                <h1 id="price"></h1>
                <h2>
                  <span id="mls"></span>
                </h2>
                <p>
                  {/* {this.state.lang["dtl_types"]} */}
                  <span id="typestyle"></span>
                </p>
              </div>
              {/* property_pricesec */}
              <ul>
                <li>
                  <i className="fa fa-bath"></i> <span id="tit-bedrooms"></span>
                </li>
                <li>
                  <i className="fa fa-bed"></i> <span id="tit-bathrooms"></span>
                </li>
                <li>
                  <i className="fa fa-car"></i> <span id="tit-parking"></span>
                </li>
              </ul>
            </div>
            {/* proprice_infosec */}

            <div className="summary_contsec section_margin">
              <Tabs
                className="tab"
                defaultActiveKey="summary"
                id="detail-tab-profile-summary"
              >
                <Tab
                  eventKey="summary"
                  className="tabcontent"
                  title={this.state.lang["dtl_summary"]}
                >
                  <div className="common_infosec summary_tabsec">
                    <ul>
                      <li>
                        <p>{this.state.lang["dtl_type"]}</p>{" "}
                        <strong>
                          <p id="pro-type"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_bedrooms"]}</p>{" "}
                        <strong>
                          <p id="pro-bedrooms"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_kitchen"]}</p>{" "}
                        <strong>
                          <p id="pro-kitchen"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_bathrooms"]}</p>{" "}
                        <strong>
                          <p id="pro-bathrooms"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_lotsize"]}</p>{" "}
                        <strong>
                          <p id="pro-lotsize"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_taxes"]}</p>{" "}
                        <strong>
                          <p id="pro-taxes"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_parking"]}</p>{" "}
                        <strong>
                          <p id="pro-parking"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_listed"]}</p>{" "}
                        <strong>
                          <p id="pro-listeddate"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_update"]}</p>{" "}
                        <strong>
                          <p id="pro-updatedate"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_basement"]}</p>{" "}
                        <strong>
                          <p id="pro-basement"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_brokered"]}</p>{" "}
                        <strong>
                          <p id="pro-broker"></p>
                        </strong>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div>{this.state.lang["dtl_extras"]}</div>
                    <strong>
                      <p id="pro-extras"></p>
                    </strong>
                  </div>
                </Tab>
                <Tab
                  eventKey="details"
                  className="tabcontent"
                  title={this.state.lang["dtl_details"]}
                >
                  <div className="common_infosec detail_tabsec">
                    <ul>
                      <li>
                        <p>{this.state.lang["dtl_style"]}</p>{" "}
                        <strong>
                          <p id="pro-construction-style"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_frontingon"]}</p>{" "}
                        <strong>
                          <p id="pro-front-on"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_watersupply"]}</p>{" "}
                        <strong>
                          <p id="pro-water-supply"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_acsystem"]}</p>{" "}
                        <strong>
                          <p id="pro-ac-system"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_heatingfuel"]}</p>{" "}
                        <strong>
                          <p id="pro-heating-fuel"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_heatingtype"]}</p>{" "}
                        <strong>
                          <p id="pro-heating-type"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_fireplace"]}</p>{" "}
                        <strong>
                          <p id="pro-fire-place"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_centralvac"]}</p>{" "}
                        <strong>
                          <p id="pro-central-vac"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_sewer"]}</p>{" "}
                        <strong>
                          <p id="pro-sewer"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_construction"]}</p>{" "}
                        <strong>
                          <p id="pro-construction"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_municipality"]}</p>{" "}
                        <strong>
                          <p id="pro-municipality"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_swimmingpool"]}</p>{" "}
                        <strong>
                          <p id="pro-swimming-pool"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_basementtype"]}</p>{" "}
                        <strong>
                          <p id="pro-basement-type"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_basementfeatures"]}</p>{" "}
                        <strong>
                          <p id="pro-basement-features"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_sizetotal"]}</p>{" "}
                        <strong>
                          <p id="pro-size-total"></p>
                        </strong>
                      </li>
                      <li>
                        <p>{this.state.lang["dtl_amenities"]}</p>{" "}
                        <strong>
                          <p id="pro-amenities"></p>
                        </strong>
                      </li>
                    </ul>
                  </div>
                </Tab>
                <Tab
                  eventKey="rooms"
                  className="tabcontent"
                  title={this.state.lang["dtl_rooms"]}
                >
                  <Button
                    id="pro-btn-mf"
                    style={{ width: "98%" }}
                    onClick={() => {
                      MeterFeetClick(this.state.dtl);
                    }}
                  >
                    Change To Feet
                  </Button>
                  <div className="common_infosec room_tabsec">
                    <ul>
                      <li>
                        <div>
                          <div
                            id="pro-room0-0"
                            className="pro_roomleft"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room0-1"
                            className="pro_roomright"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p
                          id="pro-room0-2"
                          className="pro_room"
                          style={{ width: "100%" }}
                        ></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room1-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room1-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room1-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room2-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room2-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room2-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room3-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room3-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room3-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room4-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room4-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room4-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room5-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room5-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room5-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room6-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room6-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room6-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room7-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room7-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room7-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room8-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room8-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room8-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room9-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room9-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room9-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room10-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room10-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room10-2" style={{ width: "100%" }}></p>
                      </li>
                      <li>
                        <div>
                          <div
                            id="pro-room11-0"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                          <div
                            id="pro-room11-1"
                            style={{ width: "50%", float: "left" }}
                          ></div>
                        </div>
                        <p id="pro-room11-2" style={{ width: "100%" }}></p>
                      </li>
                    </ul>
                  </div>
                </Tab>
              </Tabs>
              <div>
                <div>{this.state.lang["dtl_remarks"]}</div>
                <div id="pro-remarks" style={{ width: "98%" }}></div>
              </div>
            </div>

            <div className="main_accordinsec section_margin">
              {/* <button className="accordion">
                <i className="fas fa-address-card"></i> Property Log
              </button>
              <div className="panel">
                <div className="inner_prohistory_sec">
                  <div id="detail_log">
                    <ul className="main_historysec"></ul>
                  </div>
                </div> */}
              {/* inner_prohistory_sec */}
              {/* </div> */}
              {/* panel */}

              <button className="accordion">
                <i className="fas fa-address-card"></i> Property History
              </button>
              <div className="panel">
                <div className="inner_prohistory_sec">
                  {/* <span className="accordin_titlecontsec">
                    Buy and sell details of the 106 calm waters crescent.
                  </span> */}
                  <div id="detail_history">
                    <ul className="main_historysec"></ul>
                  </div>
                </div>
                {/* inner_prohistory_sec */}
              </div>
              {/* panel */}

              <button className="accordion">
                <i className="fas fa-clipboard-list"></i> Census Data
              </button>
              <div className="panel">
                <iframe
                  id="iframe_census"
                  src=""
                  style={{
                    width: "100%",
                  }}
                  frameBorder="no"
                  border="0"
                  marginWidth="0"
                  marginHeight="0"
                  scrolling="no"
                  allowtransparency="yes"
                  position="relative"
                  overflow="auto"
                />
              </div>
              {/* panel */}

              <button className="accordion">
                <i className="fas fa-clipboard-list"></i> Community Sold/Lease
                Reports
              </button>
              <div className="panel">
                <iframe
                  id="iframe_community"
                  src=""
                  style={{
                    width: "100%",
                  }}
                  frameBorder="no"
                  border="0"
                  marginWidth="0"
                  marginHeight="0"
                  scrolling="no"
                  allowtransparency="yes"
                  position="relative"
                  overflow="auto"
                />
              </div>
              {/* panel */}

              <button className="accordion">
                <i className="fas fa-school"></i> Avaliable Schools
              </button>
              <div className="panel">
                <iframe
                  id="iframe_school"
                  src=""
                  style={{
                    width: "100%",
                    height: document.body.clientWidth * 1.59,
                  }}
                  frameBorder="no"
                  border="0"
                  marginWidth="0"
                  marginHeight="0"
                  scrolling="no"
                  allowtransparency="yes"
                  position="relative"
                  overflow="auto"
                />
              </div>
              {/* panel */}

              <button className="accordion">
                <i className="fas fa-clipboard-list"></i> WalkScore Report
              </button>
              <div className="panel">
                <iframe
                  id="iframe_walkscore"
                  src=""
                  style={{
                    width: "100%",
                  }}
                  frameBorder="no"
                  border="0"
                  marginWidth="0"
                  marginHeight="0"
                  scrolling="no"
                  allowtransparency="yes"
                  position="relative"
                  overflow="auto"
                />
              </div>
              {/* panel */}
            </div>
            {/* main_accordinsec */}

            <div className="features_iconsec section_margin">
              <div className="inner_featiconsec">
                <ul>
                  <li>
                    <img
                      src={require("./../images/map_icon.png").default}
                      alt=""
                    />{" "}
                    <span>Map</span>
                  </li>
                  <li>
                    <img
                      src={require("./../images/transit_icon.png").default}
                      alt=""
                    />{" "}
                    <span>Transit Score</span>
                  </li>
                  <li>
                    <img
                      src={require("./../images/community_icon.png").default}
                      alt=""
                    />{" "}
                    <span>Community</span>
                  </li>
                  <li>
                    <img
                      src={require("./../images/street_icon.png").default}
                      alt=""
                    />{" "}
                    <span>Street View</span>
                  </li>
                </ul>
              </div>
              {/* inner_featiconsec */}
            </div>
            {/* features_iconsec */}
          </div>
          {/* wrapper_margin */}
        </div>
        {/* wrapper */}

        <br />
        <div id="footer"></div>
      </div>
    );
  }

  initLanguage() {
    if (navigator.language === "zh-CN") {
      this.setState({
        lang: zhj,
      });
    }
  }

  async loadDetail() {
    try {
      var url = document.location.toString();
      if (url[url.length - 1] === "=") {
        // remove the last '=' character
        url = url.slice(0, -1);
      }
      var sh = url.split("?").splice(1, 1);
      this.setState({ sh: sh });
      axios.defaults.baseURL = datahost;
      axios.defaults.headers.post["Content-Type"] = "application/json";
      let param = "\"{'sh':'" + sh + "'}\"";
      axios.post("/share/listing", param).then((res) => {
        this.setState({ dtl: res.data });

        // Load Images
        let arr = initImageLinksSharing(this.state.sh, this.state.dtl.apsl);
        this.setState({ images: arr });

        // Data
        if (this.state.dtl.data != undefined) {
          SetupPageValues(this.state.dtl.data);
        }

        // Log
        // if (this.state.dtl.log != undefined) {
        //   createLog(this.state.dtl.log);
        // }

        // History
        if (this.state.dtl.history != undefined) {
          createHistory(this.state.dtl.history);
        }

        // Community
        this.setState({
          community_loaded: createCommunityChartSharing(
            this.state.dtl.data,
            this.state.dtl.apsl,
            this.state.sh,
            this.state.community_loaded
          ),
        });
        fixFrameHeight("iframe_community", 1.03);

        // Census
        this.setState({
          census_loaded: createCensusDataSharing(
            this.state.dtl.data,
            this.state.sh,
            this.state.census_loaded
          ),
        });
        fixFrameHeight("iframe_census", 0.97);

        // Walkscore
        if (this.state.dtl.walkscore != undefined) {
          this.setState({
            walkscore_loaded: createWalkScoreSharing(
              this.state.dtl.data,
              this.state.sh,
              this.state.walkscore_loaded
            ),
          });
        }
        fixFrameHeight("iframe_walkscore", 1.01);

        // FindSchool
        this.setState({
          school_loaded: createSchoolReport(
            this.state.dtl.apsl,
            this.state.school_loaded
          ),
        });
        fixFrameHeight("iframe_school", 0.55);
      });
    } catch {}
  }
}
