import { ReadValue, CheckValue, priceSwitch } from "./Basic.js";
import {
  gesture,
  findschoolhost,
  pic_host,
  pic_link,
  pic_sharing,
} from "./Const.js";

// export function initImageLinks(tk, asl) {
//   let buff = Buffer.from(asl.PicBin, 'base64');
//   var binStr = "";
//   for (var i = 0; i < buff.length; i++) {
//     binStr += (buff[i].toString(2)).padStart(8, "0");
//   }
//   var arr = [];
//   for ( var i = 0; i < binStr.length; i++) {
//     if (binStr[i] === "1") {
//       var ngwStr = "3574765509," + asl.propertyId + ",2028-01-08 15:46:58,0" + "";
//       ngwStr = window.btoa(ngwStr).replaceAll("=", "~");
//       let linkStr = gesture + pic_host + pic_link + tk + "&ngw=" + ngwStr;
//       arr.push({ ngw: ngwStr, link: linkStr });
//     }
//   }
//   return arr;
// }

export function initImageLinks(tk, asl) {
  var binStr = "";
  if (asl.picBin == null) {
    binStr = "11111111111111111111111111111111";
  } else {
    let buff = Buffer.from(asl.PicBin, "base64");
    for (var j = 0; j < buff.length; j++) {
      binStr += buff[j].toString(2).padStart(8, "0");
    }
  }
  var arr = [];
  for (var i = 0; i < binStr.length; i++) {
    if (binStr[i] === "1") {
      var ngwStr = "3574765509," + asl.PropertyId + ",2028-01-08 15:46:58," + i;
      ngwStr = window.btoa(ngwStr).replaceAll("=", "~");
      let linkStr = gesture + pic_host + pic_link + tk + "&ngw=" + ngwStr;
      arr.push({ ngw: ngwStr, link: linkStr });
    }
  }
  return arr;
}

export function initImageLinksSharing(sh, apsl) {
  var binStr = "";
  if (apsl.picBin == null) {
    binStr = "11111111111111111111111111111111";
  } else {
    let buff = Buffer.from(apsl.picBin, "base64");
    for (var j = 0; j < buff.length; j++) {
      binStr += buff[j].toString(2).padStart(8, "0");
    }
  }
  var arr = [];
  for (var i = 0; i < binStr.length; i++) {
    if (binStr[i] === "1") {
      var ngwStr =
        "3574765509," + apsl.propertyId + ",2028-01-08 15:46:58," + i;
      ngwStr = window.btoa(ngwStr).replaceAll("=", "~");
      let linkStr = gesture + pic_host + pic_sharing + sh + "&ngw=" + ngwStr;
      arr.push({ ngw: ngwStr, link: linkStr });
    }
  }
  return arr;
}

export function SetupPageValues(data) {
  if (data !== null && !data.toString().includes("Invalid")) {
    document.getElementById("mls").innerHTML = ReadValue(data["mlNum"]);
    document.getElementById("price").innerHTML =
      "$" + priceSwitch(ReadValue(data["lpDol"]));
    document.getElementById("address").innerHTML = ReadValue(data["addr"]);
    // document.getElementById("taxes").innerHTML = ReadValue(data["taxes"]);
    document.getElementById("typestyle").innerHTML = ReadValue(
      data["typeOwn1Out"]
    );

    document.getElementById("pro-type").innerHTML =
      ReadValue(data["typeOwn1Out"]) + " " + ReadValue(data["style"]);
    if (ReadValue(data["brPlus"]) === "") {
      document.getElementById("pro-bedrooms").innerHTML = ReadValue(data["br"]);
      document.getElementById("tit-bedrooms").innerHTML = ReadValue(data["br"]);
    } else {
      document.getElementById("pro-bedrooms").innerHTML =
        ReadValue(data["br"]) + "+" + ReadValue(data["brPlus"]);
      document.getElementById("tit-bedrooms").innerHTML =
        ReadValue(data["br"]) + "+" + ReadValue(data["brPlus"]);
    }
    document.getElementById("pro-kitchen").innerHTML = ReadValue(
      data["numKit"]
    );
    document.getElementById("pro-bathrooms").innerHTML = ReadValue(
      data["bathTot"]
    );
    document.getElementById("tit-bathrooms").innerHTML = ReadValue(
      data["bathTot"]
    );
    if (ReadValue(data["frontFt"]) === "") {
      document.getElementById("pro-lotsize").innerHTML = "-";
    } else {
      document.getElementById("pro-lotsize").innerHTML =
        ReadValue(data["frontFt"]) + "ft x " + ReadValue(data["depth"]) + "ft";
    }
    document.getElementById("pro-taxes").innerHTML = ReadValue(data["taxes"]);
    if (ReadValue(data["garSpaces"]) !== "") {
      document.getElementById("pro-parking").innerHTML +=
        ReadValue(data["garSpaces"]) + " " + ReadValue(data["garType"]);
      document.getElementById("tit-parking").innerHTML = ReadValue(
        data["garSpaces"]
      );
    }
    if (ReadValue(data["parkSpcs"]) !== "") {
      document.getElementById("pro-parking").innerHTML +=
        "<br/>" + ReadValue(data["parkSpcs"]) + " Parking Drive";
    }
    if (ReadValue(data["totParkSpcs"]) !== "") {
      document.getElementById("pro-parking").innerHTML +=
        "<br/>" + ReadValue(data["totParkSpcs"] + " Total Parking Spaces");
    }
    document.getElementById("pro-parking").innerHTML =
      ReadValue(data["garSpaces"]) +
      " " +
      ReadValue(data["garType"]) +
      "<br/>" +
      ReadValue(data["parkSpcs"]) +
      " Parking Drive<br/>" +
      ReadValue(data["totParkSpcs"] + " Total Parking Spaces");
    document.getElementById("pro-listeddate").innerHTML = ReadValue(
      data["inputDate"]
    ).substring(0, 10);
    document.getElementById("pro-updatedate").innerHTML = ReadValue(
      data["timestampSql"]
    ).substring(0, 10);
    document.getElementById("pro-broker").innerHTML = ReadValue(data["rltr"]);
    document.getElementById("pro-basement").innerHTML =
      ReadValue(data["bsmt1Out"]) + " " + ReadValue(data["bsmt2Out"]);

    document.getElementById("pro-construction-style").innerHTML = ReadValue(
      data["typeOwn1Out"]
    );
    document.getElementById("pro-front-on").innerHTML = ReadValue(
      data["compPts"]
    );
    document.getElementById("pro-water-supply").innerHTML = ReadValue(
      data["water"]
    );
    document.getElementById("pro-ac-system").innerHTML = ReadValue(data["ac"]);
    document.getElementById("pro-heating-fuel").innerHTML = ReadValue(
      data["fuel"]
    );
    document.getElementById("pro-heating-type").innerHTML = ReadValue(
      data["heating"]
    );
    if (JSON.stringify(data).includes("Fireplace")) {
      document.getElementById("pro-fire-place").innerHTML = "Yes";
    } else {
      document.getElementById("pro-fire-place").innerHTML = "No";
    }
    document.getElementById("pro-central-vac").innerHTML = CheckValue(
      data["centralVac"]
    );
    document.getElementById("pro-sewer").innerHTML = ReadValue(data["sewer"]);
    document.getElementById("pro-construction").innerHTML = ReadValue(
      data["constr1Out"]
    );
    document.getElementById("pro-municipality").innerHTML = ReadValue(
      data["municipality"]
    );
    document.getElementById("pro-swimming-pool").innerHTML = CheckValue(
      data["pool"]
    );
    document.getElementById("pro-basement-type").innerHTML = ReadValue(
      data["bsmt1Out"]
    );
    document.getElementById("pro-basement-features").innerHTML = ReadValue(
      data["bsmt2Out"]
    );
    document.getElementById("pro-size-total").innerHTML =
      ReadValue(data["frontFt"]) + " x " + ReadValue(data["depth"]);
    document.getElementById("pro-amenities").innerHTML = (
      ReadValue(data["propFeat1Out"]) +
      " " +
      ReadValue(data["propFeat2Out"]) +
      " " +
      ReadValue(data["propFeat3Out"]) +
      " " +
      ReadValue(data["propFeat4Out"]) +
      " " +
      ReadValue(data["propFeat5Out"]) +
      " " +
      ReadValue(data["propFeat6Out"])
    ).trimEnd();
    document.getElementById("pro-extras").innerHTML = ReadValue(data["extras"]);

    for (var i = 0; i < 12; i++) {
      if (ReadValue(data["rm" + (i + 1) + "Out"]) === "") {
        document.getElementById("pro-room" + i + "-0").hidden = true;
        document.getElementById("pro-room" + i + "-1").hidden = true;
        document.getElementById("pro-room" + i + "-2").hidden = true;
      } else {
        document.getElementById("pro-room" + i + "-0").innerHTML =
          ReadValue(data["rm" + (i + 1) + "Out"]) +
          " (" +
          ReadValue(data["level" + (i + 1)]) +
          ")";
        if (
          ReadValue(data["rm" + (i + 1) + "Len"]) === "" ||
          ReadValue(data["rm" + (i + 1) + "Wth"]) === ""
        ) {
          document.getElementById("pro-room" + i + "-1").innerHTML = "-";
        } else {
          document.getElementById("pro-room" + i + "-1").innerHTML =
            ReadValue(data["rm" + (i + 1) + "Len"]) +
            "m x " +
            ReadValue(data["rm" + (i + 1) + "Wth"]) +
            "m";
        }
        if (ReadValue(data["rm" + (i + 1) + "Dc1Out"]) !== "") {
          document.getElementById("pro-room" + i + "-2").innerHTML +=
            ReadValue(data["rm" + (i + 1) + "Dc1Out"]) + ", ";
        }
        if (ReadValue(data["rm" + (i + 1) + "Dc2Out"]) !== "") {
          document.getElementById("pro-room" + i + "-2").innerHTML +=
            ReadValue(data["rm" + (i + 1) + "Dc2Out"]) + ", ";
        }
        if (ReadValue(data["rm" + (i + 1) + "Dc3Out"]) !== "") {
          document.getElementById("pro-room" + i + "-2").innerHTML += ReadValue(
            data["rm" + (i + 1) + "Dc3Out"]
          );
        }
      }
    }
    document.getElementById("pro-remarks").innerHTML = data["adText"];
    // document.getElementById('').innerHTML = ReadValue(data['']);
  } else {
  }
}

export function createImages(arr) {
  var st = document.getElementById("testing");
  for (var i = 0; i < arr.length; i++) {
    st.children[0].innerHTML = buildImageElement(arr[i]);
  }
}

export function buildImageElement(ele) {
  let str0 = '<div className="inner_bannersec"><img src="';
  let str1 = '" alt="" /></div>';
  return str0 + ele + str1;
}

export function createLog(arr) {
  var st = document.getElementById("detail_log");
  if (arr !== null && arr !== []) {
    for (var i = 0; i < arr.length; i++) {
      st.children[0].innerHTML += buildLogElement(arr[i]);
      // st.children[0].innerHTML += '<li>' + arr[i] + '</li>';
    }
  }
}

export function createHistory(arr) {
  var st = document.getElementById("detail_history");
  if (arr !== null && arr !== []) {
    for (var i = 0; i < arr.length; i++) {
      let ele = arr[i].split("#");
      st.children[0].innerHTML += buildHistoryElement(ele);
      // st.children[0].innerHTML += '<li>' + arr[i] + '</li>';
    }
  }
}

function buildLogElement(ele) {
  var result = "";
  result += '<li><span class="prohis_leftsec"><h1>';
  // result += priceSwitch(ele.Id.split(".")[0]);
  result += ele.KeyName;
  result += "Change</h1>";
  result += "<small>From  ";
  result += priceSwitch(ele.KeyValue0.split(".")[0]);
  result += "  to  ";
  result += priceSwitch(ele.KeyValue1.split(".")[0]);
  result += "</small><p>Date: <b>";
  result += ele.OptDate.split("T")[0];
  result += "</p></span></li>";
  return result;
}

function buildHistoryElement(arr) {
  var result = "";
  result += '<li><span class="prohis_leftsec"><h1>$';
  result += priceSwitch(arr[1].split(".")[0]);
  result += "<small>(mls: #";
  result += arr[2];
  result += ")</small></h1><p>Listed At: <b>";
  result += arr[3].split(" ")[0];
  result += "</b> / Expiry At: <b>";
  result += arr[4].split(" ")[0];
  result +=
    '</b></p></span><span class="prohis_rightsec"><p class="prostatus">Status : ';
  result += arr[5];
  result += "</p></span></li>";
  return result;
}

export function createSchoolReport(asl, loaded) {
  var st = document.getElementById("iframe_school");
  if (asl !== null) {
    if (loaded === false) {
      var ngw = "";
      if (asl.X !== undefined && asl.Y !== undefined) {
        ngw = window.btoa(asl.X + "," + asl.Y).replaceAll("=", "~");
      } else {
        ngw = window.btoa(asl.x + "," + asl.y).replaceAll("=", "~");
      }
      st.hidden = false;
      st.src = gesture + findschoolhost + "/findschool?" + ngw;
      return true;
    }
  }
  return false;
}

export function createCensusData(dtl, tk, loaded) {
  var st = document.getElementById("iframe_census");
  if (dtl !== null) {
    if (dtl["zip"] !== null) {
      if (loaded === false) {
        st.hidden = false;
        st.src =
          gesture +
          document.location.host +
          "/censusdata?" +
          tk +
          "=" +
          dtl["zip"].substring(0, 3);
        return true;
      }
    }
  }
  return false;
}

export function createCensusDataSharing(dtl, sh, loaded) {
  var st = document.getElementById("iframe_census");
  if (dtl !== null) {
    if (dtl["zip"] !== null) {
      if (loaded === false) {
        st.hidden = false;
        st.src =
          gesture +
          document.location.host +
          "/shcensusdata?" +
          sh +
          "=" +
          dtl["zip"].substring(0, 3);
        return true;
      }
    }
  }
  return false;
}

export function createWalkScore(dtl, tk, loaded) {
  var st = document.getElementById("iframe_walkscore");
  if (dtl !== null) {
    if (loaded === false) {
      let bs64 = window.btoa(ReadValue(dtl["addr"])).replaceAll("=", "~");
      st.hidden = false;
      st.src =
        gesture + document.location.host + "/walkscore?" + tk + "=" + bs64;
      return true;
    }
  }
  return false;
}

export function createWalkScoreSharing(dtl, sh, loaded) {
  var st = document.getElementById("iframe_walkscore");
  if (dtl !== null) {
    if (loaded === false) {
      let bs64 = window.btoa(ReadValue(dtl["addr"])).replaceAll("=", "~");
      st.hidden = false;
      st.src =
        gesture + document.location.host + "/shwalkscore?" + sh + "=" + bs64;
      return true;
    }
  }
  return false;
}

export function createCommunityChart(dtl, asl, tk, loaded) {
  var st = document.getElementById("iframe_community");
  if (dtl !== null && !dtl.toString().includes("Invalid")) {
    if (loaded === false) {
      let data = eval(dtl);
      var cmt = "," + data["community"];
      var curDate = new Date();
      var month = curDate.getFullYear() + "-" + curDate.getMonth() + "-01";
      if (curDate.getMonth() === 0) {
        month = curDate.getFullYear() - 1 + "-12-01";
      }
      var tp = asl.T;
      if (tp === undefined) tp = asl.t;
      var param = "chart;" + cmt + ";" + "S" + ";" + month + ";3;" + tp;
      var bs64 = window.btoa(param).replaceAll("=", "~");
      st.hidden = false;
      st.src =
        gesture + document.location.host + "/reportchart?" + tk + "=" + bs64;
      return true;
    }
  }
  return false;
}

export function createCommunityChartSharing(dtl, asl, sh, loaded) {
  var st = document.getElementById("iframe_community");
  if (dtl !== null && !dtl.toString().includes("Invalid")) {
    if (loaded === false) {
      let data = eval(dtl);
      var cmt = "," + data["community"];
      var curDate = new Date();
      var month = curDate.getFullYear() + "-" + curDate.getMonth() + "-01";
      if (curDate.getMonth() === 0) {
        month = curDate.getFullYear() - 1 + "-12-01";
      }
      var tp = asl.T;
      if (tp === undefined) tp = asl.t;
      var param = "chart;" + cmt + ";" + "S" + ";" + month + ";3;" + tp;
      var bs64 = window.btoa(param).replaceAll("=", "~");
      st.hidden = false;
      st.src =
        gesture + document.location.host + "/shreportchart?" + sh + "=" + bs64;
      return true;
    }
  }
  return false;
}

function calcPageHeight(doc) {
  var cHeight = Math.max(
    doc.body.clientHeight,
    doc.documentElement.clientHeight
  );
  var sHeight = Math.max(
    doc.body.scrollHeight,
    doc.documentElement.scrollHeight
  );
  var height = Math.max(cHeight, sHeight);
  return height;
}

export function fixFrameHeight(iframeName, fixSize) {
  var iframe = document.getElementById(iframeName);
  iframe.onload = function () {
    var iDoc =
      iframe.contentDocument || iframe.document || iframe.contentWindow;
    var height = calcPageHeight(iDoc);
    iframe.style.height = height * fixSize + "px";
  };
}

export function addAccordionClick() {
  var acc = document.getElementsByClassName("accordion");
  var i;
  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    });
  }
}

export function MeterFeetClick(dtl) {
  var btn = document.getElementById("pro-btn-mf");
  if (
    dtl !== null &&
    !dtl.toString().includes("Invalid")
  ) {
    let data = eval(dtl);
    if (btn.innerText === "Change To Feet") {
      for (var i = 0; i < 12; i++) {
        let len = (ReadValue(data["rm" + (i + 1) + "Len"]) * 3.28)
          .toString()
          .substring(0, 5);
        let wid = (ReadValue(data["rm" + (i + 1) + "Wth"]) * 3.28)
          .toString()
          .substring(0, 5);
        document.getElementById("pro-room" + i + "-1").innerHTML =
          len + "ft x " + wid + "ft";
      }
      btn.innerText = "Change To Meter";
    } else {
      for (var i = 0; i < 12; i++) {
        document.getElementById("pro-room" + i + "-1").innerHTML =
          ReadValue(data["rm" + (i + 1) + "Len"]) +
          "m x " +
          ReadValue(data["rm" + (i + 1) + "Wth"]) +
          "m";
      }
      btn.innerText = "Change To Feet";
    }
  }
}
